import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

// @material-ui/icons
import Check from "@material-ui/icons/Check";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-dashboard-pro-react/views/customCheckboxStyle";
const useStyles = makeStyles(styles);

export default function CustomCheckbox(props) {
  const classes = useStyles();

  const { error, success, labelText, options, 
          onChange, value, id, labelProps, formControlProps } = props;

  const checkboxToggle = (event, option) => {
    const currentIndex = value.indexOf(option);
    const newChecked = [...value];
    if (currentIndex === -1) {
      newChecked.push(option);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    onChange(event, newChecked);
  };
 
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }

  var formControlLabelClasses;
  if (formControlProps !== undefined) {
    formControlLabelClasses = classNames(
      formControlProps.className,
      classes.formControlLabel
    );
  } else {
    formControlLabelClasses = classes.formControlLabel;
  }

  return (
    <FormControl {...formControlProps} className={labelText !== undefined ? formControlLabelClasses : formControlClasses}>
      {labelText !== undefined ? (
        <FormLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </FormLabel> ) : null
      }
      <GridContainer>
        <GridItem>
          {options.map((option, key) => {
            return (
              <FormControlLabel
                control={
                  <Checkbox
                    tabIndex={-1}
                    checked={value.includes(option)}
                    icon={<Check className={classes.uncheckedIcon} />}
                    checkedIcon={
                      <Check className={classes.checkedIcon} />
                    }
                    onClick={(event) => checkboxToggle(event, option)}
                    classes={{
                      checked: classes.checked,
                      root: classes.checkRoot
                    }}
                  />
                }
                key={key}
                classes={{
                  label: classes.label,
                  root: classes.labelRoot
                }}
                label={option}
              />
            );
          })}
        </GridItem>
      </GridContainer>


    </FormControl>
  )
}

CustomCheckbox.defaultProps = {
  value: []
}

CustomCheckbox.propTypes = {
  labelText: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
}
