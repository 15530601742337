import React from "react";
import cx from "classnames";
import { Switch, Route, Redirect } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { Avatar, Drawer, List, ListItem, ListItemAvatar, ListItemText } from "@material-ui/core";
import { Drawer, } from "@material-ui/core";

// core components
import Footer from "components/Footer/Footer.js";
import image1 from "assets/img/sidebar-1.jpg";

// echelon components
import Notice from "../components/Notice";
import ClientNavbar from "components/Navbars/Client/ClientNavbar";
import ProgressIndicator from "views/Components/ProgressIndicator/ProgressIndicator";
import NavigationMenuView from "./Drawers/NavigationMenuView";

import * as routes from "routes/clientRoutes";

import styles from "assets/jss/material-dashboard-pro-react/layouts/clientStyle.js";
const useStyles = makeStyles(styles);

var ps;
export default function ClientLayoutView(props) {
  const { menucolor, backgroundcolor, sidebarminimized, logo, showProgressIndicator, onResetTour,
          onBgColorChange, onMenuColorChange, onSidebarMinimize, brandText=process.env.REACT_APP_WEBSITE_NAME, 
          bgColor="black", rtlActive=false, requirements, tourstate=null, ...rest } = props;

  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(window.innerWidth < 960);
  // eslint-disable-next-line
  const [filter, setFilter] = React.useState({ownerIds: []});
  const [drawer, setDrawer] = React.useState(null);

  const openDrawer = (drawerToOpen) => {
    setDrawer(drawer !== drawerToOpen ? drawerToOpen : null);
  }

  const openDocumentDrawer = (ownerIds) => {
    setFilter({...filter, ownerIds: ownerIds});
    openDrawer('documents');
  }

  const closeDocumentDrawer = () => {
    setFilter({...filter, ownerIds: []});
    openDrawer(null);
  }

  // styles
  const classes = useStyles();
  const mainPanelClasses =
    classes.mainPanel +
    " " +
    cx({
      [classes.mainPanelSidebarMini]: sidebarminimized,
      [classes.mainPanelWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
    });
  // ref for main panel div
  const mainPanel = React.createRef();
  // effect instead of componentDidMount, componentDidUpdate and componentWillUnmount
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);

    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  });
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const getRoutes = routes => {
    const { access, authorized } = props;
    return routes.dashClientRoutes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === process.env.REACT_APP_CLIENT_PLATFORM) {
        return (
          <Route
            path={prop.layout + prop.path}
            render={(props) => <prop.component filter={filter} authorized={authorized}
                                                access={access} openDocumentDrawer={openDocumentDrawer} 
                                                {...props}/>}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const resizeFunction = () => {
    setMobileOpen(window.innerWidth < 960);
  };

  const { authorized, access } = props;
  return (
    <div className={classes.wrapper}>
      <div className={mainPanelClasses} ref={mainPanel}>
        <ClientNavbar
          position={tourstate === 'started' ? 'relative' : 'sticky'}
          brandText={brandText}
          handleDrawerToggle={handleDrawerToggle}
          toggleProfilePanel={()=>openDrawer('profile')}
          toggleNavigationMenu={() => openDrawer('navigation')}
          toggleOptionsPanel={() => openDrawer('options')}
          onResetTour={onResetTour}
          {...rest}>
          <ProgressIndicator />
        </ClientNavbar>

        <Notice />

        {/* Business information panel panel / drawer */}
        {authorized && access ? 
        (<Drawer anchor={"right"} open={drawer === 'profile'} 
                onClose={() => setDrawer(null)}
                PaperProps={{className: (mobileOpen ? classes.fullDrawer : classes.businessDrawer)}}>
          <div className={classes.optionsContent} style={{ backgroundImage: "url(" + image1 + ")" }}/>
          <div className={classes.panelContent}>
            <routes.profile.component {...props} filter={filter}/>
          </div>
        </Drawer>) : null
        }

        {/* Requirements information panel panel / drawer */}
        <Drawer anchor={"right"} open={drawer === 'requirements'} 
                onClose={() => setDrawer(null)}
                PaperProps={{className: (mobileOpen ? classes.fullDrawer : classes.contentDrawer)}}
                classes={{paper: classes.blackBackground}}
                >
          <div className={classes.fullHeightPanelContent}>
            <routes.requirements.component {...props} filter={filter}/>
          </div>
        </Drawer>

        {/* Requirements information panel panel / drawer */}
        <Drawer anchor={"right"} open={drawer === 'documents'} 
                onClose={closeDocumentDrawer}
                PaperProps={{className: (mobileOpen ? classes.fullDrawer : classes.documentDrawer)}}
                classes={{paper: classes.blackBackground}}
                >
          <div className={classes.fullHeightPanelContent}>
            <routes.documents.component {...props} filter={filter}/>
          </div>
        </Drawer>

        {authorized && access ? 
        (<NavigationMenuView
          logo={logo}
          bgColor={bgColor}
          brandText={brandText}
          rtlActive={rtlActive}
          requirements={requirements}
          paperPropsClassName={mobileOpen ? classes.fullDrawer : classes.menuDrawer}
          drawer={drawer}
          setDrawer={setDrawer}
        />) : null
        }

        {/* Options panel / drawer */}
        {authorized && access ? 
        (<Drawer anchor={"right"} open={drawer === 'options'}
                onClose={() => setDrawer(null)}
                PaperProps={{className: (mobileOpen ? classes.fullDrawer : classes.optionsDrawer)}}>
          <div className={classes.panelContent}>
            <routes.options.component {...props} filter={filter} 
                setFilter={setFilter} closeDrawer={() => setDrawer(null)}
            />
          </div>
        </Drawer>) : null
        }

        {/* content classes */}
        <div className={classes.content}>
          <div className={classes.container}>
           <Switch>
              {getRoutes(routes)}
              <Redirect from={process.env.REACT_APP_CLIENT_PLATFORM} 
                          to={process.env.REACT_APP_CLIENT_PROPOSAL} />
            </Switch>
          </div>
        </div>
        {!mobileOpen ? <Footer fluid /> : null}
      </div>
    </div>
  );
}
