import React from 'react';
import { makeStyles } from "@material-ui/core";

// react component for creating beautiful carousel
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import fallbackImage from "assets/img/building.jpeg";

import styles from "assets/jss/material-dashboard-pro-react/views/artifactCarouselStyle.js";
const useStyles = makeStyles(styles);

export default function ArtifactCarouselView(props) {
  const { artifacts, slides, height } = props;
  const settings = {
    dots: true,
    arrows: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    rows: 1,
    autoplay: true
  }; 
  const classes = useStyles();
  const heights = { Medium: classes.medium, Small: classes.small, Large: classes.large };
  return (
    <div className={classes.section} id="carousel">
      <GridContainer>
        <GridItem xs={12} sm={12} md={11} className={classes.marginAuto}>
          <Carousel {...settings}
            slidesToShow={artifacts.length > slides ? slides : artifacts.length}>
            {artifacts.map((image, key) => {
              return (
                <Card key={key} plain centered>
                  <div className={classes.cardHeader + " " + classes.cardHeaderImage}>
                    <img src={image[1]} alt={image[0]} className={heights[height]} />
                    <div className="slick-caption">
                      <h4>{image[0]}</h4>
                    </div>
                  </div>
                </Card>
              );
              })
            }
          </Carousel>
          {artifacts && artifacts.length === 0 ?
            (<Carousel {...settings}>
                <Card plain centered outlineColor={"Black"}>
                  <div className={classes.cardHeader + " " + classes.cardHeaderImage}>
                    <img src={fallbackImage} alt="Exaspaces Cover Asset" className="slick-image" />
                    <div className="slick-caption">
                      <h4>Exaspaces Cover Image</h4>
                    </div>
                  </div>
                </Card>
            </Carousel>) : null
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}