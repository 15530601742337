import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Route, Switch, Redirect } from "react-router-dom";

import ReactWaterMark from 'react-watermark-component';

import ClientLayout from 'layouts/ClientLayout/ClientLayout';

class App extends Component {

	constructor(props) {
		super();

		this.state = {
			waterMarkText: 'Echelon platform',

			options: {
				chunkWidth: 200,
				chunkHeight: 70,
				textAlign: 'left',
				textBaseline: 'bottom',
				globalAlpha: 0.17,
				font: '16px Microsoft Yahei',
				rotateAngle: -0.26,
				fillStyle: '#777'
			},

			modifiedDate: -1,
		}
	}

	static getDerivedStateFromProps(props, state) {
		const { proposal, settings } = props;
		if(proposal && proposal.modifiedDate > state.modifiedDate) {
			const text = settings && settings.traits ? settings.traits.basic.watermark : localStorage.getItem("businessName");
			const { traits, modifiedDate } = proposal;
			const waterMarkText = traits.watermark && traits.watermark.includes("Add watermark") ? text : '';
			return {
				waterMarkText: waterMarkText,
				modifiedDate: modifiedDate,
			}
		}

		return null;
	}

	render() {
		const { waterMarkText, options } = this.state;

		return (
			<div>
				<ReactWaterMark
					waterMarkText={waterMarkText}
					options={options}
				>
					<Switch>
						<Route path={process.env.REACT_APP_CLIENT_PLATFORM} component={ClientLayout} />
						<Redirect from="/" to={process.env.REACT_APP_CLIENT_PLATFORM} />
					</Switch>
				</ReactWaterMark>
			</div>
		);
	}
}

const mapStateToProps = state => {
	return {
		settings: state.settings.settings,
		proposal: state.proposal.proposal,
	};
};

const mapDispatchToProps = dispatch => {
	return {
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(App));