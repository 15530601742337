import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Pagination } from '@material-ui/lab';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function PaginationView(props) {
  const { siblingCount=2, page, pages, onChange, root=true, ...rest } = props;

  const classes = useStyles();
  return (
    <div className={root ? classes.root : null}>
      <Pagination {...rest} page={page} count={pages} onChange={onChange} siblingCount={siblingCount} boundaryCount={1} />
    </div>
  );
}
