import React from "react";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Dropdown from "views/Components/Dropdown";

// echelon components

export default function OptionFiltersView(props) {
  const { options, filter, setFilter } = props;

  const filters = filter.filters ? filter.filters : [];
  const furnishing = filter.furnishing ? filter.furnishing : [];
  const classification = filter.classification ? filter.classification : [];

  const onSetFilters = (value, key) => {
    setFilter({...filter, [key]: value});
  }

  return (
    <Card>
      <CardBody>    
        <GridContainer justifyContent="flex-end">
          <GridItem xs={12} sm={12} md={12}>
            <Dropdown
              title="Furnishing"
              options={options.furnishing}
              value={furnishing}
              onChange={(event) => onSetFilters(event.target.value, 'furnishing')}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Dropdown
              title="Classification"
              options={options.classification}
              value={classification}
              onChange={(event) => onSetFilters(event.target.value, 'classification')}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={12}>
            <Dropdown
              title="Status filters"
              options={options.filters}
              value={filters}
              onChange={(event) => onSetFilters(event.target.value, 'filters')}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>
  )
}
