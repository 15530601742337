import React from 'react';
import { makeStyles } from "@material-ui/core";

// react component for creating beautiful carousel
import Carousel from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"

// core components
import Card from "components/Card/Card.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Danger from 'components/Typography/Danger';

import styles from "assets/jss/material-dashboard-pro-react/views/artifactCarouselStyle.js";
const useStyles = makeStyles(styles);

export default function ProposalArtifactCarouselView(props) {
  const { artifacts } = props;

  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  }; 
  const classes = useStyles();
  return (
    <div className={classes.section} id="carousel">
      <GridContainer>
        <GridItem xs={12} sm={12} md={11} className={classes.marginAuto}>
          <Carousel {...settings}>
            {artifacts.map((image, key) => {
              return (
                <Card key={key} plain centered>
                  <div className={classes.cardHeader + " " + classes.cardHeaderImage}>
                    <img src={image[1]} alt={image[0]} className="slick-image" />
                    <div className="slick-caption">
                      <h4>{image[0]}</h4>
                    </div>
                  </div>
                </Card>
              );
            })
          }
          </Carousel>
          {artifacts && artifacts.length === 0 ?
          (<GridContainer justify="center">
              <Danger><h3><small>No assets to display</small></h3></Danger>
          </GridContainer>) : null
          }
        </GridItem>
      </GridContainer>
    {/* // </div> */}
    </div>
  );
}