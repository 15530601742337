import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import cx from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Button, IconButton } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

// material-ui icons
import MenuIcon from "@material-ui/icons/Menu";
import MoreIcon from "@material-ui/icons/MoreVert";
import TourIcon from "@material-ui/icons/Directions";

// core components
import placeholder from "assets/img/image_placeholder.jpg"

import styles from "assets/jss/material-dashboard-pro-react/components/clientNavbarStyle.js";
const useStyles = makeStyles(styles);

export default function ClientNavbar(props) {
  const classes = useStyles();
  const { color="white", brandText="Exaspaces", url, position="sticky",
          toggleProfilePanel, toggleOptionsPanel, toggleNavigationMenu, onResetTour } = props;
  const appBarClasses = cx({
    [" " + classes[color]]: color
  });
  // const url = 
  const onLogoError = (event) => {
    event.target.onerror=null;
    event.target.src=placeholder;
  }
  return (
    <AppBar position={position} className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Button 
          size={"small"} edge="start" className={classes.menuButton + " navigationTour-step1"} 
          startIcon={<MenuIcon/>} color="inherit" aria-label="menu" onClick={() => toggleNavigationMenu()}>
            {brandText}
        </Button>

        <div className={classes.flex} />
        
        <IconButton edge="start" className={classes.menuButton} size={"small"} 
          onClick={() => toggleProfilePanel()}
          color="inherit" aria-label="menu">
            <img src={url} style={{maxWidth: "64px", maxHeight: "40px"}} alt="" onError={onLogoError}/>
        </IconButton>
        <Button 
          size={"small"} edge="start" className={classes.menuButton} 
          startIcon={<TourIcon className={classes.icon}/>} color="inherit" aria-label="menu" onClick={() => onResetTour()}>
            START TOUR
        </Button>
        <IconButton 
          edge="start" color="inherit" aria-label="menu"
          onClick={() => toggleOptionsPanel()}>
            <MoreIcon className={"navigationTour-step2"} />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
}

ClientNavbar.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  brandText: PropTypes.string,
  miniActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  sidebarMinimize: PropTypes.func
};
