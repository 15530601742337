// Notice
export const NOTICE_SHOW = "NOTICE_SHOW";
export const NOTICE_HIDE = "NOTICE_HIDE";

// authorize action types
export const AUTHORIZE_START = 'AUTHORIZE_START';
export const AUTHORIZE_SUCCESS = 'AUTHORIZE_SUCCESS';
export const AUTHORIZE_FAILURE = 'AUTHORIZE_FAILURE';
export const AUTHORIZE_LOGOUT = 'AUTHORIZE_LOGOUT';

// artifact (aka dossier) action types 
export const ARTIFACT_START = 'ARTIFACT_START';
export const ARTIFACT_SUCCESS = 'ARTIFACT_SUCCESS';
export const ARTIFACT_REMOVE = 'ARTIFACT_REMOVE';
export const ARTIFACT_FAILURE = 'ARTIFACT_FAILURE';

// corporate settings action types 
export const SETTINGS_START = 'SETTINGS_START';
export const SETTINGS_SUCCESS = 'SETTINGS_SUCCESS';
export const SETTINGS_FAILURE = 'SETTINGS_FAILURE';

// proposal and proposal units
export const PROPOSAL_START = 'PROPOSAL_START';
export const PROPOSAL_SUCCESS = 'PROPOSAL_SUCCESS';
export const PROPOSAL_FAILURE = 'PROPOSAL_FAILURE';

// proposal unit action types 
export const PROPOSAL_UNIT_START = 'PROPOSAL_UNIT_START';
export const PROPOSAL_UNIT_SUCCESS = 'PROPOSAL_UNIT_SUCCESS';
export const PROPOSAL_UNIT_FAILURE = 'PROPOSAL_UNIT_FAILURE';

// proposal history types 
export const PROPOSAL_HISTORY_START = 'PROPOSAL_HISTORY_START';
export const PROPOSAL_HISTORY_SUCCESS = 'PROPOSAL_HISTORY_SUCCESS';
export const PROPOSAL_HISTORY_FAILURE = 'PROPOSAL_HISTORY_FAILURE';

// business information
export const BUSINESS_START = 'BUSINESS_START';
export const BUSINESS_SUCCESS = 'BUSINESS_SUCCESS';
export const BUSINESS_FAILURE = 'BUSINESS_FAILURE';

// buidling action types 
export const REQUIREMENT_START = 'REQUIREMENT_START';
export const REQUIREMENT_SUCCESS = 'REQUIREMENT_SUCCESS';
export const REQUIREMENT_FAILURE = 'REQUIREMENT_FAILURE';

// business information
export const TOUR_START = 'TOUR_START';
export const TOUR_END = 'TOUR_END';

// user personalization action types 
export const PERSONALIZATION_START = 'PERSONALIZATION_START';
export const PERSONALIZATION_SUCCESS = 'PERSONALIZATION_SUCCESS';
export const PERSONALIZATION_FAILURE = 'PERSONALIZATION_FAILURE';
