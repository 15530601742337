import axios from "store/axios-broker";
import * as types from 'store/types';

const started = (request) => {
	return {
		type: types.ARTIFACT_START,
		request: request
	};
};

const succeeded = (artifacts, artifact, key='artifacts') => {
	const entities = artifacts ? {[key]: artifacts, [key+'modifiedDate']: new Date()} : {};
	const entity = artifact ? {[key]: artifact, [key+'modifiedDate']: new Date()} : {};
	return {
		type: types.ARTIFACT_SUCCESS,
		key: key,
		artifact: artifact,
		entity: entity,
		entities: entities,
	};
};

const failed = (error) => {
	return {
		type: types.ARTIFACT_FAILURE,
		error: error && error.data && error.data.code ? error.data.code : "000"
	};
};

export const retrieve = (url, key='artifacts') => {
	return dispatch => {
		dispatch(started('retrieve'));
		axios.get(url).then(response => {
			dispatch(succeeded(response.data, null, key));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};

export const retrieveartifacts = (proposalId, key='artifacts') => {
	return retrieve("/apex/consumer/" + proposalId + "/proposal/documents", key);
}
