import React from 'react';

// @material-ui/core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";

// echelon components
import ProposalArtifactListView from './ProposalArtifactListView';
import ProposalArtifactCarouselView from './ProposalArtifactCarouselView';

export default function ProposalArtifactView(props) {
  const { images, attachments } = props;

  return (
      <div>
        <GridContainer> 

          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardHeader plain>
                <CardText><b>Assets</b></CardText>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <ProposalArtifactCarouselView
                      headers={["Document title", "Type", ""]}
                      title={"assets"}
                      artifacts={images}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>    
            </Card>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardHeader plain>
                <CardText><b>Documents</b></CardText>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <ProposalArtifactListView
                      headers={["Document title", "Type", ""]}
                      title={"attachments"}
                      artifacts={attachments}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>    
            </Card>
          </GridItem>

          {/* Empty card to ensure that the above card is fully visible */}
          <GridItem xs={12} sm={12} md={12}>
            <Card raised>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                  </GridItem>
                </GridContainer>
              </CardBody>    
            </Card>
          </GridItem>
        </GridContainer>
      </div>
  );
}