import React from "react";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

// core components
import Button from "components/CustomButtons/Button";

// echelon components
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

export default function OptionShareView(props) {
  const { proposal, onClickShare } = props;

  const contacts = proposal ? proposal.traits.contacts.map((contact) => contact.name) : [];
  const forwards = proposal && proposal.traits.forwards ? proposal.traits.forwards.map((contact) => contact.emailId) : [];
  return (
    <Card>
      <CardBody>
        <GridContainer justifyContent="flex-end">
          <GridItem xs={12} sm={12} md={12}>
            <AutocompleteTags
              id="proposal-shared-with"
              placeholder=""
              labelText="Shared with (by broker)"
              clearText=""
              options={contacts}
              value={contacts}
              onChange={() => {}}
            />
          </GridItem>
          
          <GridItem xs={12} sm={12} md={12}>
            <AutocompleteTags
              id="proposal-shared-with"
              placeholder=""
              labelText="Shared with (by colleagues)"
              clearText=""
              options={forwards}
              value={forwards}
              onChange={() => {}}
            />
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <Button
              id="proposal-share-with-others"
              round
              fullWidth
              color="gray"
              onClick={onClickShare}
            >Share with my colleagues</Button>
          </GridItem>

        </GridContainer>
      </CardBody>
    </Card>
  )
}
