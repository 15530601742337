import React, { Component } from 'react';
import { connect } from "react-redux";

import CustomLinearProgress from "components/CustomLinearProgress/CustomLinearProgress";

class ProgressIndicator extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showProgressIndicator: false,
      modifiedDate: -1,
   }
  }

  static getDerivedStateFromProps(props, state) {
    const { progress } = props;
    if(progress && progress.modifiedDate > state.modifiedDate) {
      return {
        showProgressIndicator: progress.loading,
        modifiedDate: progress.modifiedDate,
      }
    }
    return null;
  }

  render() {
    return (
      <div>
        {this.state.showProgressIndicator ? <CustomLinearProgress /> : null }
      </div>
    );  
  }
}

const mapStateToProps = (state) => {
  return {
    progress: state.progress,
  };
};

const mapDispatchToProps = () => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProgressIndicator);