import React from "react";

// @material-ui components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader";

import styles from "assets/jss/material-dashboard-pro-react/views/businessViewStyles.js";
const useStyles = makeStyles(styles);

export default function BusinessView(props) {
  const { name, address, phone, email, justify="center"} = props;

  const classes = useStyles();
  const url = `/apex/consumer/${localStorage.getItem("businessId")}/business/photo?xspaces-app=ecp`;
  return (
    <div>
      <GridContainer justify="center">
          <Card product container centered>
            <GridContainer justify={justify}>
              <GridItem xs={6} sm={6} md={6}>
                <CardHeader stats image>
                  <img src={url} alt="..." />
                </CardHeader>
              </GridItem>
            </GridContainer>
            <CardBody>
              <Card plain centered>
                <CardBody plain>
                  <h3 className={classes.cardTitle}>{name}</h3>
                  <p className={classes.description}>
                    {address.line1}<br></br>
                    {address.line2}<br></br>
                    {address.locality} – {address.postalCode}<br></br><br></br>
                  </p>
                  <div className={classes.price}>
                    <b>({phone.prefix}) {phone.number}</b><br></br>
                    <b>{email}</b>
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
      </GridContainer>
    </div>
  )
}