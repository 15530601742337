import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null, loading: false
};

const started = (state, action) => {
	return update( state, {
		request: action.request, error: null, loading: true
	});
};

const succeeded = (state, action) => {
	const histories = action.histories ? action.histories : state.histories;
	const addedhistories = state.request === 'create' ? [...histories, action.history] : histories;

	return update( state, {
		histories: addedhistories,
		history: action.history,
		modifiedDate: new Date(),
		loading: false
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const proposalhistory = (state = initial, action) => {
	switch ( action.type ) {
		case types.PROPOSAL_HISTORY_START: 
			return started(state, action);

		case types.PROPOSAL_HISTORY_SUCCESS: 
			return succeeded(state, action);

		case types.PROPOSAL_HISTORY_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default proposalhistory;