import React from "react";
import { makeStyles } from "@material-ui/core";

// core components
import GridContainer from 'components/Grid/GridContainer';
import Card from "components/Card/Card.js";

// echelon components
import Proffer from "../Proffer/Proffer";

const styles = {
  grid: {
    margin: "0 -15px",
    marginTop: "-10px",
    width: "calc(100% + 30px)"
  }
};

const useStyles = makeStyles(styles);

export default function LandingView(props) {
  const classes = useStyles();
  return (
    <GridContainer>
      <Card className={classes.grid}>
          <Proffer
            openDocumentDrawer={props.openDocumentDrawer}
            authorized={props.authorized}
            access={props.access}
            filter={props.filter}
          />
      </Card>
    </GridContainer>
  );
}