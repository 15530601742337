import React from "react";
import { makeStyles, Tooltip, Divider, Badge } from "@material-ui/core";

// @material icons
import AcceptIcon from "@material-ui/icons/ThumbUp";
import ActivityIcon from "@material-ui/icons/QuestionAnswer";
import RejectIcon from "@material-ui/icons/ThumbDown";
import ClarificationIcon from "@material-ui/icons/HeadsetMic";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Quote from "components/Typography/Quote";

// echelon components
import { messages } from "variables/messages";

import styles from "assets/jss/material-dashboard-pro-react/views/profferViewStyle.js";
const useStyles = makeStyles(styles);

export default function CompareView(props) {
  const { compact, rows, units, unitIds, unitsTable, messageKey, disclaimer,
          onClickActions, openDocumentDrawer } = props;

  const makeActionButtons = (unit, index) => {
    return (
      [ { color: "info", icon: ClarificationIcon, tooltip: 'Seek clarification for proposed unit', state: 'Clarification', count: 0   },
        { color: "success", icon: AcceptIcon, tooltip: 'Accept proposed unit', state: 'Accepted', count: 0  },
        { color: "danger", icon: RejectIcon, tooltip: 'Reject proposed unit', state: 'Rejected', count: 0  },
        { separator: true },
        { color: "primary", icon: ActivityIcon, tooltip: 'View comments', state: '', count: unit[9].length  },
      ].map((prop, key) => {
        const { color, tooltip, icon, count, state, separator} = prop;
        if(separator) {
          return <Divider key={key} orientation="vertical" flexItem className={classes.divider} />;
        }
        return (
          <Tooltip key={key} title={tooltip} placement="top">
            <Button
              simple={unit[3] !== state}
              noDebounce
              color={color}
              className={icon ? classes.actionButton : classes.actionTextButton}
              onClick={() => onClickActions(unit[7], index+1, key, tooltip, unit[9])}
              key={key}>
                {icon ? 
                <Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} 
                        badgeContent={count} variant="dot" color="secondary">
                    <prop.icon className={classes.icon} />
                </Badge>
                : tooltip
                }
            </Button>
          </Tooltip>
        );
    }));  
  }

  const actionButtons = (unitsIds, units) => {
    const preview = localStorage.getItem("preview");
    const actionRow = unitsIds.map((unitsId, index) => {
      if(preview === "true") {
        return (<h4><small>Preview mode</small></h4>);
      }

      return <GridContainer justifyContent="center">{makeActionButtons(unitsId, index)}</GridContainer>
    });
    return ['', ...actionRow];
  }
  
  const documentLinks = (unitsIds, units) => {
    const links = unitsIds.map((unitsId, key) => {
      return (
        <Button
          simple
          noDebounce
          color="primary"
          className={classes.actionButton}
          onClick={() => openDocumentDrawer([unitsId[7], unitsId[8]])}
          key={key}>
          View
        </Button>
      );
    });

    return ['Assets & attachments', ...links, ''];
  }

  const classes = useStyles();
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          {rows > 0 ?
          (<div>
            {/* <TableContainer style={{maxHeight: "380px"}}> */}
              <Table
                hover
                striped
                verticalGridLines
                groupRowColor="info"
                tableHeaderColor="warning"
                compact={compact}
                tableHead={['', ...props.header]}
                tableData={[actionButtons(unitIds, units), ...unitsTable, documentLinks(unitIds, units)]}
                customClassesForCells={[0,1,2,3,4]}
                customCellClasses={[classes.attributeName + " " + classes.gray, classes.center, classes.center, classes.center, classes.center]}
                customHeadClassesForCells={[0]}
                customHeadCellClasses={[classes.right]}
              />
            {/* </TableContainer> */}
            <GridContainer justifyContent="center">
              <GridItem xs={12} sm={10} md={10}>
                <Quote small text={disclaimer} author={localStorage.getItem("businessName")}/>
              </GridItem>
            </GridContainer>
          </div>) :
          (<GridContainer justifyContent="center">
            <h3 ><small>{messages[messageKey]}</small></h3>
          </GridContainer>)
          }
        </GridItem>
      </GridContainer>
    </div>
  );
}