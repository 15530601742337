import Business from "views/Client/Profiles/Business/Business";

// @material-ui icons
import RequirementIcon from "@material-ui/icons/Category";
import BusinessIcon from "@material-ui/icons/Business";
import DocumentIcon from "@material-ui/icons/Attachment";

import ClientLanding from "views/Client/Landing/Landing";
import RequirementDetails from "views/Client/Requirement/RequirementDetails";
import ProposalArtifact from "views/Client/Artifact/ProposalArtifact";
import Options from "views/Client/Proffer/Options/Options";

export const profile = {
  component: Business
};

export const requirements = {
  component: RequirementDetails
};

export const documents = {
  component: ProposalArtifact
};

export const options = {
  component: Options
};

export const menuroutes = [
  {
    path: "/requirements",
    menu: 'Requirements',
    drawer: 'requirements',
    icon: RequirementIcon,
    component: RequirementDetails,
  },
  {
    path: "/documents",
    menu: 'Attached documents',
    drawer: 'documents',
    icon: DocumentIcon,
    component: RequirementDetails,
  },
  {
    path: "/about",
    menu: 'About us',
    drawer: 'profile',
    icon: BusinessIcon,
    component: Business,
  },
];

export const dashClientRoutes = [
  {
    path: "/proposal/:proposalId",
    component: ClientLanding,
    layout: process.env.REACT_APP_CLIENT_PLATFORM
  },

];
