import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from "@material-ui/core/Divider";

import styles from "assets/jss/material-dashboard-pro-react/views/dropdownStyle.js";
const useStyles = makeStyles(styles);

export default function Dropdown(props) {
  const classes = useStyles();
  const { id, title, options, value, multiple, error, attribute,
          success, disabled, labelProps, onChange } = props;

  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error
  });

  return (
    <FormControl
      id={id+"-form-control"}
      fullWidth
      className={classes.selectFormControl}
    >
      {title !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {title}
        </InputLabel>
      ) : null}
      <Select
        id={id+"-select-options"}
        multiple={multiple}
        value={value}
        onChange={onChange}
        MenuProps={{ className: classes.selectMenu }}
        classes={{ select: classes.select + " " + classes.dropTextCase }}
        disabled={disabled}
        inputProps={{
          name: id,
          id: id
        }}>

        <MenuItem
          disabled
          classes={{
            root: classes.selectMenuItem
          }}>
          {title}
        </MenuItem>
        {options && 
          options.map((option, key) => {
            if (option.divider) {
              return (
                <Divider
                  key={key}
                  // onClick={() => handleCloseMenu("divider")}
                  className={classes.dropdownDividerItem}
                />
              );
            }
            return (
              <MenuItem
                classes={{
                  root: classes.selectMenuItem,
                  selected: multiple === false ? classes.selectMenuItemSelected : classes.selectMenuItemSelectedMultiple
                }}
                key={key}
                value={option}>
                {attribute ? option[attribute] : option}
              </MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
}

Dropdown.defaultProps = {
  multiple: true,
  attribute: undefined,
}

Dropdown.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(PropTypes.any).isRequired,
  value: PropTypes.any,
  attribute: PropTypes.string,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
}
