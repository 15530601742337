import React from 'react';
import moment from "moment";

// @material-ui/core components
import { makeStyles, withStyles } from '@material-ui/core';
import { Avatar, Divider, List, ListItem, ListItemAvatar, ListItemText, Tooltip } from "@material-ui/core";

// core components
import {
  successColor,
} from "assets/jss/material-dashboard-pro-react.js";

import styles from "assets/jss/material-dashboard-pro-react/components/notificationsStyle.js";
const useStyles = makeStyles(styles);

const StyledListItem = withStyles({
  root: {
    backgroundColor: "transparent",
    "&.Mui-selected": {
      backgroundColor: successColor[6]
    }
  },
})(ListItem);

export default function CommentsView(props) {
  const { comments } = props;
  const classes = useStyles();
  return (
    <List className={classes.commentlist}>
      {comments.map((comment, key) => {
        const { posterId, posterName, post, traits } = comment;
        const time = moment(new Date(comment.time * 1000));
        var selected = false;
        if(traits && traits.category) {
          selected = traits.category === 'broker';
        }
        return (
          <React.Fragment key={key}>
            <StyledListItem 
                dense selected={selected}
                alignItems="flex-start" className={classes.rowHover}>
              <ListItemAvatar>
                <Avatar alt={comment.posterName} src={`/apex/porter/${posterId}/document`} />
              </ListItemAvatar>
              <ListItemText
                id={"labelId"+key}
                primary={
                  <React.Fragment>
                    <span className={classes.primaryText}>{posterName}</span>
                    <span className={classes.spanRight}>
                      <Tooltip placement="top-end" title={time.format('DD MMM YYYY HH:mm a')} classes={{ tooltip: classes.tooltip }}>
                        <div>{time.fromNow()}</div>
                      </Tooltip>
                    </span>
                  </React.Fragment>}
                secondary={
                  <React.Fragment>
                    <span className={classes.secondaryText}>{post}</span>
                  </React.Fragment>}
              />
            </StyledListItem>
            <Divider variant="inset" component="li" />
          </React.Fragment>
        );
      })}
    </List>
  );
}