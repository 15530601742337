// Imports

import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from 'react-redux';

// custom components
import ClientLayoutView from "layouts/ClientLayout/ClientLayoutView";

import * as personalization from 'store/actions/personalization';

class ClientLayout extends Component {
  constructor(props) {
    super();

    const cachedsettings = localStorage.getItem("settings");
    const settings = cachedsettings ? JSON.parse(cachedsettings) : {
                                        menucolor: props.menucolor,
                                        backgroundcolor: props.backgroundcolor,
                                        backgroundimage: props.backgroundimage,
                                        sidebarminimized: props.sidebarminimized,
                                      };
    this.state = {
      settings: settings,
      logo: require("assets/img/exaspaces.png"),

      progressModifiedDate: -1,
      showProgressIndicator: false,

      // proposal information
      proposal: null,
      requirements: null,
      modifiedDate: -1,
      url: null,

      access: null,
      authorized: false,
      authorizedDate: -1,

      tour: {
        state: null,
        modifiedDate: -1,
      }
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { code, modifiedDate, authorizedDate, proposal, tour } = props;

    var stateTour = state.tour;
    if(tour && tour.modifiedDate > stateTour.modifiedDate) {
      stateTour = tour;
    }
 
    if(proposal && proposal.modifiedDate > state.modifiedDate) {
      const { traits } = proposal;
      return ({
        tour: stateTour,
        proposal: proposal,
        requirements: traits.requirement,
        authorized: true,
        access: traits.shareLink !== false,
        url: `/apex/consumer/${localStorage.getItem("businessId")}/business/photo?xspaces-app=ecp`,
      });
    }

    if(code && modifiedDate > authorizedDate) {
      return ({
        tour: stateTour,
        authorized: true,
        authorizedDate: modifiedDate,
      });
    }

    return null;
  }
  
  onResetTour = () => {
    const tourId = localStorage.getItem("navigationTourId");
    if(tourId) {
      this.props.update({[tourId]: 'new'});
    }
  }

  render() {
    const { url, settings, requirements, authorized, access, tour } = this.state;
    return <ClientLayoutView
      {...this.props} 
      {...settings}
      url={url}
      requirements={requirements}
      authorized={authorized}
      access={access}
      logo={this.state.logo}
      tourstate={tour.state}
      showProgressIndicator={this.state.showProgressIndicator}
      onResetTour={this.onResetTour}
    />
  }
}

ClientLayout.defaultProps = {
  menucolor: "blue",
  backgroundcolor: 'black',
  backgroundimage: 0,
  sidebarminimized: false,
}

ClientLayout.propTypes = {
  menucolor: PropTypes.string,
  backgroundcolor: PropTypes.string,
  backgroundimage: PropTypes.number,
  sidebarminimized: PropTypes.bool,
}

const mapStateToProps = (state, ownProps) => {
  return {
    code: state.authorize.code,
    modifiedDate: state.authorize.modifiedDate,
    proposal: state.proposal.proposal,
    tour: state.tour,
  };
};

const mapDispatchToProps = dispatch => {
  const userId = localStorage.getItem("userId");
  return {
    update: (tours) => dispatch(personalization.update('tours', tours, null, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLayout);