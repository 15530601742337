import React from "react";

// @material-ui/icons
import InputAdornment from '@material-ui/core/InputAdornment';

// @material-ui/core components

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// custom components
import CustomDatetime from "views/Components/CustomDatetime";
import CustomNumber from "views/Components/CustomNumber/CustomNumber";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

export default function GeneralRequirementView(props) {  
  const { onChangeHandler } = props;
  const { valid, specification } = props;

  const onMonthChanged = () => {
  }

  return (
    <div>
      <GridContainer>
        {/* Time preference */}
        <GridItem xs={12} sm={12} md={12}>
          <CustomDatetime 
            labelText="Occupation timeframe"
            id="occupation-timeframe"
            dateFormat="MMMM YYYY"
            readOnly={true}
            formControlProps={{
              fullWidth: true,
            }}
            value={specification.from ? new Date(specification.from) : null}
            onChange={(date) => onMonthChanged(date)}
          />
        </GridItem>

        {/* Location preference & area */}
        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-preferred-location"
            labelText="Preferred location"
            clearText=""
            placeholder=""
            error={valid.location === false}
            options={specification.location ? specification.location : []}
            value={specification.location ? specification.location : []}
            onChange={(event, tags, reason) => {
              // onChangeHandler(tags, 'location', 'specification', true);
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomNumber 
            labelText="Min area"
            id="area-range-start"
            error={valid.rangestart === false}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">Sqft</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'rangestart', 'specification', true);
            }}
            value={specification.rangestart}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomNumber 
            labelText="Max area"
            id="area-range-end"
            error={valid.rangeend === false}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">Sqft</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'rangeend', 'specification', true);
            }}
            value={specification.rangeend}
          />
        </GridItem>

        {/* price/rate */}
        <GridItem xs={12} sm={12} md={6}>
          <CustomNumber 
            labelText="Min price"
            id="price-range-start"
            error={valid.pricestart === false}
            formControlProps={{
              fullWidth: true,
              required: true
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">INR</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'pricestart', 'specification', true);
            }}
            value={specification.pricestart}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomNumber 
            labelText="Max price"
            id="price-range-end"
            error={valid.priceend === false}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">INR</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'priceend', 'specification', true);
            }}
            value={specification.priceend}

          />
        </GridItem>

      </GridContainer>
    </div>
  )
}