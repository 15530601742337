import React, { Component } from 'react';
import { connect } from 'react-redux';

import Units from './Units';

// common components
import * as proposalunit from "store/actions/proposalunit";
import * as proposalhistory from "store/actions/proposalhistory";
import * as artifact from "store/actions/artifact";

class Proposal extends Component {
  constructor(props) {
    super();
    this.state = {
      proposal: {},
      proposalId: null,
      modifiedDate: -1,

      // metadata
      structure: {},
      mappedattributes: {},
      flattended: [],
      attributenames: [],

      // view state
      compactView: true,
      mapdefaults: {zoom: 11, center: {lat: 23.221155289224342, lng:77.56347706748005}},

      disclaimer: '',
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { proposal, settings } = props;
    if(proposal && proposal.modifiedDate > state.modifiedDate) {
      const { mappedattributes, configuration, structure, flattenstructure, mapDefaults } = proposal.traits;
      const flattended = Object.keys(mappedattributes).map((key) => ({name: key, ...flattenstructure[key]}));
      return {
        proposalId: proposal.proposalId,
        proposal: proposal,

        mappedattributes: mappedattributes,
        structure: structure['Proposal'],
        flattended: flattended,
        attributenames: Object.keys(mappedattributes).map((key) => key),

        mapdefaults: {...state.mapdefaults, ...mapDefaults},
        compactView: configuration && configuration.compact ? configuration.compact : false,

        disclaimer: settings.traits ? settings.traits.basic.disclaimer : '',
        modifiedDate: proposal.modifiedDate,
      }
    }
  
    return null;
  }

  componentDidUpdate(prevprops) {
    const { proposal } = this.props;
    if(!prevprops.proposal && proposal && proposal.proposalId) {
      const { proposalId, traits } = proposal;
      if(traits.shareLink !== false) {
        this.props.retrievehistory(proposalId);
        this.props.retrieveunits(proposalId);
        this.props.retrieveartifacts(proposalId);
      }
    }
  }

  render() {
    const { Component, ComponentProps } = this.props;
    const { modifiedDate, ...rest } = this.state;
    return (
      <Units 
        Component={Component}
        {...ComponentProps}
        {...rest}
      />
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    proposal: state.proposal.proposal,
    settings: state.settings.settings,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    retrieveunits: (proposalId) => dispatch(proposalunit.retrieve(proposalId)),
    retrievehistory: (proposalId) => dispatch(proposalhistory.retrieve(proposalId)),
    retrieveartifacts: (proposalId) => dispatch(artifact.retrieveartifacts(proposalId, 'proposal')),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Proposal);
