/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui icons
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";
import ReplyIcon from "@material-ui/icons/Reply";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";

// echelon components
import CustomSwitch from "../CustomSwitch";
import Comments from "./Comments";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function CommentsDialog(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { comments, dirty, maxWidth="sm", open=false, autoreply=false, mandatory=true, action='',
          title="Clarifications and comments", placeholder="Enter your clarifications and comments", 
          onClose, onSendComment} = props;

  const [compact, setCompact] = React.useState(maxWidth === "sm");
  const [show, setShow] = React.useState(autoreply);
  const [post, setPost] = React.useState("");

  return (
    <Dialog
      fullWidth={true}
      maxWidth={compact ? "sm" : "md"}
      scroll={'paper'}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title}</h4>
      </DialogTitle>
      {comments ?
      (<DialogContent
        dividers={true}
        id="classic-modal-slide-description"
      >
        <Comments 
          dirty={dirty}
          comments={comments}
        />
      </DialogContent>) : null
      }
      <DialogActions>
        {!show ?
        (<CustomSwitch 
          checked={compact}
          onChange={(event) => {
            setCompact(!compact);
          }}
          label='Compact view'
        />) : null
        }
        {show ? 
        (<CustomInput
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            multiline: true,
            rows:5,
            value: post,
            onChange: event => {
              setPost(event.target.value);
            },
            placeholder: placeholder,
          }}
        />) : null}
        {(!show && onSendComment) ?
        (<Button className={classes.marginRight}
          color="success"
          onClick={()=>setShow(!show)}
        >
          <ReplyIcon className={classes.icons} /> Reply
        </Button>) : null
        }
        {show ? 
        (<Button
          justIcon
          color="gray"
          disabled={(mandatory && post === "")}
          onClick={()=>onSendComment(action, post)}
        >
          <SendIcon />
        </Button>
        ): null
        }
      </DialogActions>
    </Dialog>

  );
}
