import React from 'react';

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText";

// echelon components
import GeneralRequirementView from './GeneralRequirementView';
import OfficeRequirementView from './OfficeRequirementView';
import RetailRequirementView from './RetailRequirementView';
import IndustrialRequirementView from './IndustrialRequirementView';

import styles from "assets/jss/material-dashboard-pro-react/views/requirementsViewStyle.js";
const useStyles = makeStyles(styles);

export default function RequirementDetailsView(props) {
  const {requirementType, basic, traits, contacts, valid} = props;
 
  const onDoNothing = () => {}

  const classes = useStyles();
  return (
      <div>
        <Card className={classes.root} >
          <CardHeader color="success">
            <b>Proposal requirements</b>
          </CardHeader>

          <CardBody>
            <GridContainer>

              <GridItem xs={12} sm={12} md={4}>
                <Card raised>
                  <CardHeader plain>
                    <CardText><b>Location and price</b></CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <GeneralRequirementView
                          {...props}
                          id="requirement-general-view"
                          requirementType={requirementType}
                          basic={basic}
                          traits={traits}
                          valid={valid}
                          contacts={contacts}
                          classes={classes}
                          formClassName={classes.customFormControlClasses}
                          onChangeHandler={onDoNothing}
                          onSelectKeyContact={onDoNothing}
                          onSelectParticipant={onDoNothing}
                          onRemoveParticipant={onDoNothing}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>    
                </Card>
              </GridItem>

              <GridItem xs={12} sm={12} md={8}>
                <Card raised>
                  <CardHeader plain>
                    <CardText><b>Furnishing and utilities</b></CardText>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        {props.type === 'Office' ?
                        (<OfficeRequirementView
                          {...props}
                          id="requirement-utilities-view"
                          basic={basic}
                          traits={traits}
                          valid={valid}
                          contacts={contacts}
                          classes={classes}
                          formClassName={classes.customFormControlClasses}
                          onChangeHandler={onDoNothing}
                          onSelectKeyContact={onDoNothing}
                          onSelectParticipant={onDoNothing}
                          onRemoveParticipant={onDoNothing}
                        />) : null
                      }
                        {props.type === 'Retail' ?
                        (<RetailRequirementView
                          {...props}
                          id="requirement-utilities-view"
                          basic={basic}
                          traits={traits}
                          valid={valid}
                          contacts={contacts}
                          classes={classes}
                          formClassName={classes.customFormControlClasses}
                          onChangeHandler={onDoNothing}
                          onSelectKeyContact={onDoNothing}
                          onSelectParticipant={onDoNothing}
                          onRemoveParticipant={onDoNothing}
                        />) : null
                      }
                        {props.type === 'Industrial' ?
                        (<IndustrialRequirementView
                          {...props}
                          id="requirement-utilities-view"
                          basic={basic}
                          traits={traits}
                          valid={valid}
                          contacts={contacts}
                          classes={classes}
                          formClassName={classes.customFormControlClasses}
                          onChangeHandler={onDoNothing}
                          onSelectKeyContact={onDoNothing}
                          onSelectParticipant={onDoNothing}
                          onRemoveParticipant={onDoNothing}
                        />) : null
                      }
                      </GridItem>
                    </GridContainer>
                  </CardBody>    
                </Card>
              </GridItem>
            </GridContainer>
          </CardBody>
        </Card>
      </div>
  );
}