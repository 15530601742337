import * as types from 'store/types';

export const startTour = (tourId) => {
	return dispatch => {
		dispatch ( {
        type: types.TOUR_START,
        tourId: tourId,
      }
    );
  }
}

export const endTour = (tourId) => {
	return dispatch => {
		dispatch ( {
        type: types.TOUR_END,
        tourId: tourId,
      }
    );
  }
}