import React from "react";

// @material-ui components
import { makeStyles } from "@material-ui/core";

// core components
import Button from "components/CustomButtons/Button";
import Card from "components/Card/Card";
import CardHeader from "components/Card/CardHeader";
import CardBody from "components/Card/CardBody";

import fallbackImage from "assets/img/register.jpeg";

import styles from "assets/jss/material-dashboard-pro-react/views/buildingCardViewStyle.js";
const useStyles = makeStyles(styles);

export default function BuildingCardView(props) {
  const { image, name, id, number, status, onClick} = props;
 
  const classes = useStyles();
  return (
    <div id={id} className={classes.cardDimension + " " + classes.cardHover}>
      <Card plain={true} product={false} {...status}>
        <CardHeader image className={classes.cardHeaderHover}>
        <a href="#home" onClick={e => onClick(e, id)}>
          <img id={id} src={image ? image : fallbackImage} alt={name}/>
        </a>
        </CardHeader>
        <CardBody>
          <div className={classes.cardHoverUnder}>
            <Button color="gray" round justIcon size="sm" onClick={() => {}}>
              {number}
            </Button>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}