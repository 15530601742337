import React, { Component } from 'react';
import { connect } from 'react-redux';

import BusinessView from "./BusinessView";

import * as company from "store/actions/business";

class Business extends Component {
  constructor(props) {
    super();

    this.state = {
      basic: {
        name: '',
        address: {},
        phone: {},
        email: '',
        website: '',
      },

      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { profile, modifiedDate } = props;
    if(profile && modifiedDate > state.modifiedDate) {
      const phone = profile.phones[0];
      if(!phone.prefix || phone.prefix === '') {
        phone.prefix = '+91';
      }
      return {
        basic:{
          name: profile.name,
          address: profile.address[0],
          phone: phone,
          email: profile.traits.email,
          website: profile.traits.website,
        },
        modifiedDate: modifiedDate,
      }
    }

    return null;
  }

  componentDidMount() {
    const businessId = localStorage.getItem("businessId");
    this.props.retrieve(businessId);
  }

  render() {
    return (
      <BusinessView 
        {...this.state.basic}
      />
    );
  }
}

const mapStateToProps = (state, props) => {
	return {
    profile: state.business.business,
    modifiedDate: state.business.modifiedDate,
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
    retrieve: (businessId) => dispatch(company.retrieve(businessId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Business);

