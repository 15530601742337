import React, { Component } from 'react';
import { connect } from 'react-redux';

// custom components
import LandingView from './LandingView';

import * as authorize from "store/actions/authorize";

class ClientLanding extends Component {
  constructor(props) {
    super();

    const proposalId = props.match.params['proposalId'];
    const code = props.location.search ? props.location.search.replace("?code=", "") : null;
    this.state = {

      proposalId: proposalId,
      code: code,
      confirmcode: '',

      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { code, modifiedDate } = props;
    if(code && modifiedDate > state.modifiedDate) {
      return {
        confirmcode: code,
        modifiedDate: modifiedDate,
      }
    }
  
    return null;
  }

  componentDidMount() {
    // remove localstorage keys
    const keys = ['proposalId', 'code', 'preview', 'businessId', 'businessName'];
    keys.map((key) => {
      localStorage.removeItem(key);
      return key;
    })

    // check and authorize access to the proposal
    const { proposalId, code } = this.state;
    if(proposalId && code) {
      this.props.authorize(proposalId, code);
    }
  }

  render() {
    return (
      <LandingView 
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    code: state.authorize.code,
    modifiedDate: state.authorize.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    authorize: (proposalId, code) => dispatch(authorize.authorize(proposalId, code)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientLanding);
