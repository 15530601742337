import React, { Component } from 'react';

import CommentsView from "./CommentsView";

class Comments extends Component {
  // define constructor
  constructor() {
    super();

    this.state = {
    }
  }

  render() {
    return (
      <CommentsView 
        comments={this.props.comments}
      />
    );
  }
}

export default Comments;