import * as types from 'store/types';
import { update } from 'variables/utilities';

const started = (state, action) => {
	return update( state, {
    state: 'started',
    tourId: action.tourId,
    modifiedDate: new Date()
	});
};

const ended = (state, action) => {
	return update( state, {
    state: 'ended',
    tourId: action.tourId,
    modifiedDate: new Date()
	});
};

const tours = (state = {}, action) => {
	switch ( action.type ) {
		case types.TOUR_START: 
			return started(state, action);

		case types.TOUR_END: 
			return ended(state, action);

		default:
			return state;
	}
};

export default tours;