
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';

import notice  from "store/reducers/notice";
import authorize from "store/reducers/authorize";
import proposal from "store/reducers/proposal";
import business from "store/reducers/business";
import requirement from "store/reducers/requirement";
import proposalunit from "store/reducers/proposalunit";
import proposalhistory from "store/reducers/proposalhistory";
import settings from 'store/reducers/settings';
import artifact from 'store/reducers/artifact';
import personalization from 'store/reducers/personalization'
import tour from 'store/reducers/tour';

const reducers = combineReducers({
  business: business,
  proposal: proposal,
  proposalunit: proposalunit,
  proposalhistory: proposalhistory,
  requirement: requirement,
  artifact: artifact,
  
  personalization: personalization,
  tour: tour,

  // platform reducers
  authorize: authorize,
  settings: settings,
  notice: notice,
});

const rootreducer = (state, action) => {
  if(action.type === "AUTHORIZE_LOGOUT") {
    state = undefined;
  }

  return reducers(state, action);
}

let composeEnhancers = null;
if (process.env.NODE_ENV === 'development') {
    composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
} else {
    composeEnhancers = compose;
}

const store = createStore(rootreducer, composeEnhancers(
  applyMiddleware(thunk)
));

export default function createStores() {
    return (store);
}