import {
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
  tooltip,
} from "assets/jss/material-dashboard-pro-react.js";

import customDropdownStyle from "assets/jss/material-dashboard-pro-react/components/customDropdownStyle.js";

const notificationsStyle = theme => ({
  ...customDropdownStyle(theme),
  tooltip,
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  size: {
    [theme.breakpoints.up("md")]: {
      minWidth: "60%",
      maxWidth: "60%",
      marginRight: "60px"
    },
    [theme.breakpoints.up("lg")]: {
      minWidth: "48%",
      maxWidth: "48%",
      marginRight: "60px"
    },
  },
  rowHover: {
    "&:hover": {
      backgroundColor: grayColor[13]
    },
    textOverflow: "ellipsis",
  },
  spanRight: {
    float: "right"
  },
  cardFooterCustom: {
    justifyContent: "end",
    marginTop: "-10px",
  },
  buttonsRight: {
    marginLeft: "0px",
    marginRight: "0px",
    padding: "4px 16px",
  },
  buttonsLeft: {
    marginLeft: "0px",
    marginRight: "0px",
    padding: "6px 0px",
  },
  buttonLink: {
    zIndex: "9",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "5px 15px 0",
      width: "auto",
      height: "auto",
      "& svg": {
        width: "30px",
        height: "24px",
        marginRight: "19px",
        marginLeft: "3px"
      },
      "& .fab,& .fas,& .far,& .fal,& .material-icons": {
        width: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        marginRight: "19px",
        marginLeft: "3px"
      }
    }
  },
  links: {
    width: "20px",
    height: "20px",
    zIndex: "4",
    [theme.breakpoints.down("sm")]: {
      display: "block",
      width: "30px",
      height: "30px",
      color: "inherit",
      opacity: "0.8",
      marginRight: "16px",
      marginLeft: "-5px"
    }
  },
  linkText: {
    zIndex: "4",
    ...defaultFont,
    fontSize: "14px",
    margin: "0!important",
    paddingLeft: "12px",
    textTransform: "none"
  },
  headerLinksSvg: {
    width: "24px !important",
    height: "24px !important"
  },
  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("md")]: {
      position: "absolute",
      top: "5px",
      border: "1px solid " + whiteColor,
      right: "5px",
      fontSize: "9px",
      background: dangerColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "16px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "14px",
      verticalAlign: "middle",
      display: "block"
    },
    [theme.breakpoints.down("sm")]: {
      ...defaultFont,
      fontSize: "14px",
      marginRight: "8px"
    }
  },
  primaryText: {
    fontSize: "16px",
  },
  secondaryText: {
    whiteSpace: "pre-wrap"    
  },
  list: {
    [theme.breakpoints.up("md")]: {
      maxHeight: '460px'
    },
    overflow: 'auto'
  },
  commentlist: {
    [theme.breakpoints.up("md")]: {
      maxHeight: '460px'
    },
    overflow: 'none',
    marginLeft: "-8px",    
    marginRight: "-4px",    
  }
});

export default notificationsStyle;
