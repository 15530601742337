import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

class History extends Component {
  constructor(props) {
    super();
    this.state = {
      histories: {},
      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { histories, modifiedDate } = props;
  
    // handle changes to the history (update history on any action)
    if(modifiedDate > state.modifiedDate) {
      const comments = histories.sort((a, b) => b.modifiedDate - a.modifiedDate )
                                .reduce((previous, current) => {
                                  const { unitId, comment } = current;
                                  const comments = previous[unitId] ? previous[unitId] : [];
                                  previous[unitId] = [...comments, comment];

                                  return previous;
                                }, {});
      return {
        comments: comments,
        modifiedDate: modifiedDate,
      }
    }

    return null;
  }

  render() {
    const { comments } = this.state;
    const { Component, ...rest } = this.props;
    return (
      <Fragment>
        <Component 
          {...rest} 
          comments={comments}
        />
      </Fragment>
    ) 
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    histories: state.proposalhistory.histories,
    modifiedDate: state.proposalhistory.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(History);  