import axios from "store/axios-broker";
import * as types from 'store/types';

const started = (request) => {
	return {
		type: types.PERSONALIZATION_START,
		request: request
	};
};

const succeeded = (preferences) => {
	return {
		type: types.PERSONALIZATION_SUCCESS,
    preferences: preferences,
	};
};

const failed = (error) => {
	return {
		type: types.PERSONALIZATION_FAILURE,
		error: error && error.data && error.data.code ? error.data.code : "000"
	};
};

export const retrieve = (userId) => {
	return dispatch => {
		dispatch(started('retrieve'));
		let url = "/apex/people/" + userId + "/preferences";
		axios.get(url).then(response => {
			const { traits } = response.data;
			if(traits && traits.settings) {
				localStorage.setItem("settings", JSON.stringify(traits.settings));
			}
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};

export const update = (key, settings, preferences, userId) => {
	return dispatch => {
		// send local update (if its settings)
		if(key === 'settings') {
			const traits = { ...preferences.traits, [key]: settings };
			dispatch(succeeded({...preferences, modifiedDate: preferences.modifiedDate + 5, traits: traits}));
		}
		let url = "/apex/people/" + userId + "/preferences/" + key;
		axios.post(url, settings).then(response => {
			// finally, update the local storage (if its settings)
			if(key === 'settings') {
				localStorage.setItem(key, JSON.stringify(settings));
			}
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
}
