import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  business: null,
};

const started = (state, action) => {
  return update( state, {
          request: action.request,
          error: null
  });
};

const succeeded = (state, action) => {
  return update( state, {
      business: action.business,
      modifiedDate: new Date()
   });
};

const failed = (state, action) => {
  return update( state, {
          error: action.error
  });
}

const business = (state = initial, action) => {
  switch ( action.type ) {
    case types.BUSINESS_START: 
        return started(state, action);

    case types.BUSINESS_SUCCESS: 
        return succeeded(state, action);

    case types.BUSINESS_FAILURE: 
        return failed(state, action);

    default:
        return state;
  }
};

export default business;