import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  userId: null,
  loading: false, request: null,
  error: null
};

const started = (state, action) => {
  return update( state, {
          request: action.request,
          error: null
  });
};

const succeeded = (state, action) => {
  return update( state, {
          proposalId: action.proposalId,
          code: action.code,
          modifiedDate: new Date()
   });
};

const failed = (state, action) => {
  return update( state, {
          error: action.error,
          code: null,
          modifiedDate: new Date(),
  });
}

const logout = (state, action) => {
  return update(state, { 
            code: null, proposalId: null
  });
};

const authorize = (state = initial, action) => {
  switch ( action.type ) {
    case types.AUTHORIZE_START: 
        return started(state, action);

    case types.AUTHORIZE_SUCCESS: 
        return succeeded(state, action);

    case types.AUTHORIZE_FAILURE: 
        return failed(state, action);

    case types.AUTHORIZE_LOGOUT: 
        return logout(state, action);
        
    default:
        return state;
  }
};

export default authorize;