import {
  grayColor,
  primaryColor,
  defaultFont,
  cardTitle,
} from "assets/jss/material-dashboard-pro-react.js";

const profferViewStyle = theme => ({
  disabled: {
    "&:before": {
      borderColor: "transparent !important"
    }
  },
  underline: {
    "&:hover:not($disabled):before,&:before": {
      borderColor: grayColor[4] + "!important",
      borderWidth: "1px !important"
    },
    "&:after": {
      borderColor: primaryColor[0]
    },
    "& + p": {
      fontWeight: "300"
    }
  },
  headerLabelMargin: {
    marginLeft: "15px",
    marginRight: "15px",
  },
  gray: {
    color: grayColor[0]
  },
  right: {
    textAlign: "right"
  },
  rightMargin: {
    textAlign: "right",
    padding: "10px !important",
  },
  center: {
    textAlign: "center",
  },
  divider: {
    marginLeft: "16px",
    border: `2px ${theme.palette.divider}`,
  },
  attributeName: {
    fontSize: "0.940rem",
    fontWeight: "400",
    paddingLeft: "24px !important",
    minWidth: "20px"
  },
  actionButton: {
    margin: "4px 0 2px 15px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  statusButton: {
    // margin: "4px 2px 2px 4px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    marginTop: "8px",
    top: "10px",
    letterSpacing: "unset",
    "& + $underline": {
      marginTop: "0px"
    }
  },
  actionTextButton: {
    margin: "-2px 0 2px 15px",
    padding: "5px",
    "& svg,& .fab,& .fas,& .far,& .fal,& .material-icons": {
      marginRight: "0px"
    }
  },
  actionButtonMargin: {
    paddingRight: "8px",
    zIndex: "9",
    position: "relative"
  },
  icon: {
    verticalAlign: "middle",
    width: "17px",
    height: "17px",
    top: "-1px",
    position: "relative"
  },
  grow: {
    flexGrow: 1,
  },
  overflow: {
    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  cardNormalTitle: {
    ...cardTitle,
    marginLeft: "30px",
    marginTop: "10px",
    marginBottom: "0px",
    borderBottom: "2px solid rgb(212, 212, 212)"
  }, 
  speedDial: {
    position: "absolute",
    right: theme.spacing(0),
    top: theme.spacing(1),
  },
});

export default profferViewStyle;
