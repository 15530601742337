import React, { Component } from 'react';
import Joyride, { STATUS } from 'react-joyride';
import { connect } from 'react-redux';

import Primary from 'components/Typography/Primary';

import * as tour from 'store/actions/tour';
import * as personalization from 'store/actions/personalization';

import {
  primaryColor,
  grayColor,
} from "assets/jss/material-dashboard-pro-react.js";

class GuidedTour extends Component {
  constructor(props) {
    super();

    this.state = {
      run: true,

      showtour: false,
      tourId: props.tourId,
      steps: props.steps,

      tours: {},
      preferences: {},
      preferencesModifiedDate: -1,      
    }

    localStorage.setItem("navigationTourId", props.tourId);
  }

  static getDerivedStateFromProps(props, state) {
    const { preferences } = props;
    if(preferences && preferences.modifiedDate > state.preferencesModifiedDate) {
      const { tours={} } = preferences.traits;
      const showtour = tours[state.tourId] !== 'completed';
      return {
        run: true,
        showtour: showtour,
        tours: tours,
        preferences: preferences,
        preferencesModifiedDate: preferences.modifiedDate,
      }
    }

    return null;
  }

  callback = (data) => {
    const { status, lifecycle } = data;
    const {tourId, tours, preferences } = this.state;
    if(status === STATUS.RUNNING && lifecycle === "init") {
      this.props.startTour(tourId);
    }
    const statuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (statuses.includes(status)) {
      this.setState({ run: false });
      this.props.endTour(tourId);

      this.props.update({...tours, [tourId]: 'completed'}, preferences);
    }
  };

  render() {
    const { run, steps, showtour=true } = this.state;
    const { disableScrolling=false } = this.props;
    return (
      <div>
        {showtour &&
        <Joyride
          continuous={true}
          run={run}
          disableScrolling={disableScrolling}
          scrollToFirstStep={true}
          showProgress={true}
          showSkipButton={false}
          steps={steps}
          primaryProps={{}}
          locale={{
              skip: <Primary>SKIP TOUR</Primary>,
              back: <Primary>BACK</Primary>,
              next: <span>NEXT</span>,
              last: <span>FINISH TOUR</span>
            }}
          styles={{
            options: {
              primaryColor: primaryColor[0],
              overlayColor: grayColor[6],
              zIndex: 9999,
            },
          }}
          callback={this.callback}
        />
        }
      </div>
    );
  }
};

const mapStateToProps = (state, props) => {
	return {
    preferences: state.personalization.preferences,
	};
};

const mapDispatchToProps = (dispatch, props) => {
  const userId = localStorage.getItem("userId");
	return {
    startTour: (tourId) => dispatch(tour.startTour(tourId)),
    endTour: (tourId) => dispatch(tour.endTour(tourId)),
    retrieve: () => dispatch(personalization.retrieve(userId)),
    update: (tours, preferences) => dispatch(personalization.update('tours', tours, preferences, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(GuidedTour);