import React from 'react';
import { makeStyles } from "@material-ui/core";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Danger from 'components/Typography/Danger';

import styles from "assets/jss/material-dashboard-pro-react/views/profferViewStyle.js";
const useStyles = makeStyles(styles);

export default function ProposalArtifactListView(props) {
  const { headers, artifacts } = props;

  const classes = useStyles();
  return (
    <div>
      <Card plain>
        <CardBody>
          <Table
            tableHeaderColor="warning"
            hover
            striped
            skipCol={3}
            customClassesForCells={[1, 2]}
            customCellClasses={[classes.right, classes.rightMargin]}
            customHeadClassesForCells={[1]}
            customHeadCellClasses={[classes.right]}
            tableHead={headers}
            tableData={artifacts}
          />
          {artifacts && artifacts.length === 0 ?
          (<GridContainer justify="center">
              <Danger><h3><small>No documents to display</small></h3></Danger>
          </GridContainer>) : null
          }
        </CardBody>
      </Card>
    </div>
  );
}