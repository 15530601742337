import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import ProposalArtifactView from './ProposalArtifactView';
import { makeLink, makeDownloadLink } from 'variables/utilities';

class ProposalArtifact extends Component {
  constructor(props) {
    super();

    this.state = {
      kind: props.kind,
      category: props.category,
      ownerId: props.ownerId,
      proposalId: localStorage.getItem("proposalId"),

      assets: [],
      images: [],
      attachments: [],
      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { artifacts, modifiedDate } = props;

    if(artifacts && modifiedDate > state.modifiedDate) {

      const assetList = artifacts.filter((artifact) => artifact.traits.kind === 'asset')
        .map((artifact) => {
          const { dossierId, ownerId, traits, contentType } = artifact;
          return ([
            makeLink(traits.title, props.path + "/" + dossierId),
            contentType,
            makeDownloadLink(artifact, "/apex/porter/" + dossierId + "/document"),
            traits.original ? traits.original.ownerId : ownerId
          ]);
      });

      const imageList = artifacts.filter((artifact) => artifact.traits.kind === 'asset')
        .map((artifact) => {
          const { dossierId, ownerId, traits } = artifact;
          return ([
            traits.title,
            "/apex/porter/" + dossierId + "/document",
            traits.original ? traits.original.ownerId : ownerId
          ]);
      });

      const attachmentList = artifacts.filter((artifact) => artifact.traits.kind === 'attachment')
        .map((artifact) => {
          const { dossierId, ownerId, traits, contentType } = artifact;
          return ([
            makeLink(traits.title, props.path + "/" + dossierId),
            contentType,
            makeDownloadLink(artifact, "/apex/porter/" + dossierId + "/document"),
            traits.original ? traits.original.ownerId : ownerId
          ]);
      });
     
      return ({
        assets: assetList,
        images: imageList,
        attachments: attachmentList,
        modifiedDate: modifiedDate,
      })
    }

    return null;
  }

  render() {
    const { assets, images, attachments, proposalId } = this.state;
    const filterIds = this.props.filter.ownerIds.length > 0 ? this.props.filter.ownerIds : [proposalId];
    const filteredAssets = assets.filter((asset) => filterIds.includes(asset[3]));
    const filteredImages = images.filter((image) => filterIds.includes(image[2]));
    const filteredAttachments = attachments.filter((attachments) => filterIds.includes(attachments[3]));

    return (
      <ProposalArtifactView
        assets={filteredAssets}
        images={filteredImages}
        attachments={filteredAttachments}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
	return {
    artifacts: state.artifact.entities['proposal'],
    modifiedDate: state.artifact.entities['proposalmodifiedDate'],
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProposalArtifact);
