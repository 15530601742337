import React from "react";

// @material-ui/icons

// @material-ui/core components
import InputAdornment from '@material-ui/core/InputAdornment';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// custom components
import Dropdown from "views/Components/Dropdown";
import CustomNumber from "views/Components/CustomNumber/CustomNumber";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

export default function IndustrialRequirementView(props) {                      
  const { valid, specification,
          onChangeHandler } = props;

  return (
    <div>
      <GridContainer>
        {/* Location preference & area */}
        <GridItem xs={12} sm={12} md={6}>
          <Dropdown
            id="required-space-type"
            title="Type of space"
            options={specification.spacetype.length > 0 ? specification.spacetype : []}
            value={specification.spacetype.length > 0 ? specification.spacetype : []}
            onChange={(event) => {
              onChangeHandler(event, 'spacetype', 'specification', true);
            }}
          />
        </GridItem>
        {/* Occupy the excess spae*/}
        <GridItem md={6}>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <CustomNumber 
            labelText="Min. floor loading capacity"
            id="min-floor-loading-capacity"
            error={valid.loadingstart === false}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">Tons</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'loadingstart', 'specification', true);
            }}
            value={specification.loadingstart}

          />
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
        <CustomNumber 
            labelText="Max. floor loading capacity"
            id="max-floor-loading-capacity"
            error={valid.loadingend === false}
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              endAdornment: <InputAdornment position="end">Tons</InputAdornment>,
            }}
            onChange={(value) => {
              onChangeHandler(value, 'loadingend', 'specification', true);
            }}
            value={specification.loadingend}

          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-utilities"
            labelText="Utilities"
            clearText=""
            placeholder=""
            options={specification.infra ? specification.infra : []}
            value={specification.infra ? specification.infra : []}
            error={valid.infra === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'infra', 'specification', true);
            }}
          />
        </GridItem>	

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-amentities"
            labelText="Amenities"
            clearText=""
            placeholder=""
            options={specification.amenities ? specification.amenities : []}
            value={specification.amenities ? specification.amenities : []}
            error={valid.amenities === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'amenities', 'specification', true);
            }}
          />
        </GridItem>	


      </GridContainer>
    </div>
  )
}