import {
  defaultFont,
  blackColor,
  dangerColor,
  successColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import customSelectStyle from "assets/jss/material-dashboard-pro-react/customSelectStyle.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const dropdownStyle = {
  ...customCheckboxRadioSwitch,
  ...customSelectStyle,
  dropTextCase: {
    textTransform: "none",
    fontSize: "14px",
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "1.42857",
    top: "4px",
    letterSpacing: "unset",
    // "& + $underline": {
    //   marginTop: "0px"
    // }
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
  mrAuto: {
    marginRight: "auto"
  },
  mlAuto: {
    marginLeft: "auto"
  },
  selectFormControl: {
    ...customSelectStyle.selectFormControl,
    marginTop: "-20px"
  }
};

export default dropdownStyle;
