import React from "react";

// core components
import Accordion from "components/Accordion/Accordion.js";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card.js";
// import Button from "components/CustomButtons/Button";

// echelon components
import CustomSwitch from "views/Components/CustomSwitch";
import OptionFiltersView from "./Sections/OptionFiltersView";
import OptionCompareView from "./Sections/OptionCompareView";
import OptionShareView from "./Sections/OptionShareView";

export default function OptionsView(props) {
  const { filter, setFilter } = props;

  const compact = filter.compact ? filter.compact : false;
  const compare = filter.compare ? filter.compare : false;

  const onSetFilters = (value, key) => {
    setFilter({...filter, [key]: value});
  }

  return (
    <div>
      <GridContainer>
       <Card>
          <GridItem xs={12} sm={12} md={12}>
            <Accordion
              active={0}
              collapses={compare ? [
                  {
                    title: "Refine units by",
                    content: <OptionFiltersView {...props} />
                  },
                  {
                    title: "Compare units",
                    content: <OptionCompareView {...props} />
                  },
                  {
                    title: "Share proposal",
                    content: <OptionShareView {...props} />
                  }, 
                ] : [
                  {
                    title: "Share proposal",
                    content: <OptionShareView {...props} />
                  },                 
              ]}
            />
          </GridItem>
        </Card>
        <Card>
          <GridItem xs={12} sm={12} md={12}>
            <CustomSwitch
                label="Turn on compare view"
                checked={compare}
                onChange={()=>onSetFilters(!compare, 'compare')}/>
          </GridItem>

          <GridItem xs={12} sm={12} md={12}>
            <CustomSwitch
                label="Use compact view"
                checked={compact}
                onChange={()=>onSetFilters(!compact, 'compact')}/>
          </GridItem>

          {/* <GridItem xs={12} sm={12} md={12}>
            <Button
              id="client-platform-restart-tour"
              round
              fullWidth
              color="gray"
              onClick={onClickRestart}
            >Restart product tour</Button>
          </GridItem> */}

        </Card>
      </GridContainer>
    </div>
  )
}
