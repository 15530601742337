import React from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core";

import {
  grayColor,
  successColor,
  dangerColor,
  infoColor,
  warningColor,
} from "assets/jss/material-dashboard-pro-react.js";

// @material-ui/icons
import Person from "@material-ui/icons/Person";

import Supervisor from "@material-ui/icons/SupervisedUserCircle";
import NoteIcon from "@material-ui/icons/Note";
import ConferenceIcon from "@material-ui/icons/Group";
import MeetingIcon from "@material-ui/icons/CalendarToday";
import CallIcon from "@material-ui/icons/Call";
import Business from "@material-ui/icons/Business";
import LinkIcon from "@material-ui/icons/Link";
import CloudDownload from "@material-ui/icons/CloudDownload";

// proposal status icons
import AcceptedIcon from "@material-ui/icons/ThumbUp";
import RejectedIcon from "@material-ui/icons/ThumbDown";
import ClarificationIcon from "@material-ui/icons/Comment";
import RemovedIcon from "@material-ui/icons/RemoveCircleOutline";
import ViewCommentsIcon from "@material-ui/icons/QuestionAnswer";

import ProposalUnitIcon from "@material-ui/icons/ShoppingCart";
import AddProposalUnitIcon from "@material-ui/icons/AddShoppingCart";
import RemoveProposalUnitIcon from "@material-ui/icons/RemoveShoppingCart";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
const useStyles = makeStyles(styles);

// function that verifies if the contents is an email address
const verifyEmail = (value, mandatory=true) => {
    var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((!mandatory && value.trim().length === 0) || emailRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if the string has the required length
const verifyLength = (value, length) => {
    if (value.trim().length >= length) {
      return true;
    }
    return false;
  };

// function that verifies if value contains only numbers
const verifyNumber = value => {
    var numberRex = new RegExp("^[0-9]+$");
    if (value.trim().length === 0 || numberRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if value contains a ratio
const verifyRatio = value => {
  const values = value.split(":");  
  const valid = values.length <= 2 ? verifyNumber(values[0]) : false;
  if(valid && values.length === 2) {
    return valid && verifyNumber(values[1]);
  }
  return valid;
};

const verifyMobile = value => {
    var numberRex = new RegExp("^[0-9]{10}$");
    if (value.trim().length === 0 || numberRex.test(value)) {
      return true;
    }
    return false;
};

const verifyPhone = (value, mandatory) => {
    var numberRex = new RegExp("^[0-9]{8,12}$");
    if ((!mandatory && value.trim().length === 0) || numberRex.test(value)) {
      return true;
    }
    return false;
};

// function that verifies if value contains only numbers
const verifyPassword = value => {
    var passwordRex = new RegExp("^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])).{6,15}$");
    if (passwordRex.test(value)) {
      return true;
    }
    return false;
};

const verifyPasswordEquals = (value, other) => {
    var passwordRex = new RegExp("^((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])).{6,15}$");
    if (passwordRex.test(value)) {
      return value === other;
    }
    return false;
};

// verifies if value is a valid URL
const verifyUrl = value => {
    try {
      if(value && value.trim().length > 0) {
        new URL(value);
      }
    } catch (_) {
      return false;
    }
    return true;
};

const verifyWebsite = (value) => {
  const valid = verifyUrl(value);
  if(valid === false) {
    var website = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
    return website.test(value);
  }
  return valid;
}

export const makeDownloadLink = (artifact, path) => {
  return (artifact.traits.type === 'Link' 
            ? <a href={artifact.name} target="blank"><LinkIcon /></a> 
            : <a download={artifact.name} href={path}><CloudDownload /></a>
        );
}

const makeLink = (display, path, state={}) => {
  const segment = window.location.pathname.endsWith("/") ? path : "/" + path;
	return (
		<div>
			<Link to={{pathname: window.location.pathname + segment, state: state}}>
				{display}
			</Link>
		</div>
	);
}

const makePartnerIcon = (traits, type) => {
  // if(type === 'Client') {
    if(traits != null && traits.category === 'individual') {
      return <Person />
    }
  // }
  // if(type === 'Landlord') {
    if(traits != null && traits.category === 'business') {
      return <Business />
    }
  // }
	return <div></div>
}

const makeSupervisorIcon = (role) => {
  if(role === 'Administrator') {
    return <Supervisor />
  }
  return null;
}

const makePrimaryContact = (traits) => {
	if(traits !== null && traits.primary && traits.primary.includes('Primary contact')) {
		return <PrimaryContact />
	}
	return <div></div>
}

export default function PrimaryContact(props) {
	const classes = useStyles();
	return (
		<Button color="success" className={classes.actionButton}>
			<Person className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitIcon = (key, status) => {
  return <ProposalUnitIcon key={key} style={{ color: warningColor[1] }} />;
}

const makeProposalUnitAddAction = (key, onClick) => {
  if(onClick) {
    return <ProposalUnitAddAction key={key} onClick={onClick}/>
  }
  return null;
}

export function ProposalUnitAddAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color="success" {...prop} className={classes.actionButton}>
			<AddProposalUnitIcon className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitViewCommentAction = (key, onClick) => {
  if(onClick) {
    return <ProposalUnitViewCommentAction key={key} onClick={onClick}/>
  }
  return null;
}

export function ProposalUnitViewCommentAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color="info" {...prop} className={classes.actionButton}>
			<ViewCommentsIcon className={classes.icon} />
		</Button>
	);
}

const makeProposalUnitRemoveAction = (key, onClick) => {
  if(onClick) {
    return <ProposalUnitRemoveAction key={key} onClick={onClick}/>
  }
  return null;
}

export function ProposalUnitRemoveAction(prop) {
	const classes = useStyles();
	return (
		<Button simple color="success" {...prop} className={classes.actionButton}>
			<RemoveProposalUnitIcon className={classes.icon} />
		</Button>
	);
}

const makeInteractionTypeColor = (type) => {
  switch(type) {
    case "Note":
      return "azure";

    case "Conference":
      return "rose";
    
    case "Meeting":
      return "orange";
    
    case "Call":
      return "green";

    case "Others":
      return "red" ;
       
    default:
      return "default";
  }
}

const makeInteractionTypeIcon = (type) => {
  switch(type) {
    case "Note":
      return <NoteIcon style={{ color: grayColor[1] }} />;

    case "Conference":
      return <ConferenceIcon style={{ color: grayColor[1] }} />;
    
    case "Meeting":
      return <MeetingIcon style={{ color: grayColor[1] }} />;
    
    case "Call":
      return <CallIcon style={{ color: grayColor[1] }} />;

    case "Others":
      return <FontAwesomeIcon icon="handshake" color="gray" size="lg"/> ;
       
    default:
      return null;
  }
}

const makeProposalStatusIcon = (status) => {
  switch(status) {
    case "Accepted":
      return <AcceptedIcon style={{ color: successColor[1] }} />;

    case "Rejected":
      return <RejectedIcon style={{ color: dangerColor[1] }} />;

    case "Clarification":
      return <ClarificationIcon style={{ color: infoColor[1] }} />;

    case "Removed":
      return <RemovedIcon style={{ color: warningColor[1] }} />;
      
    default:
      return null;
  }
}

const updateCustomAttribute = (attributes, index, name, value, type='text') => {
  const clone = attributes ? [...attributes] : [];
  if(index >=0) {
    clone[index] = {name: name, value: value, type: type};
  } else {
    clone.push({name: name, value: value, type: type});
  }

  return clone;
}

const updatestate = (state, event, attribute, type, valid) => {
  if(attribute) {
    const value = event && event.target ? event.target.value : event;
    const updatedObject = update(state[type], {
            [attribute]: value } );

    const updatedValidity = update(state.valid, {
            [attribute]:  valid});
            
    return { [type]: updatedObject,
              valid: updatedValidity,
              dirty: true };      
  } else {
    const updatedValidity = update(state.valid, {
              [type]:  valid});
    
    return { [type]: event,
              valid: updatedValidity,
              dirty: true }; 
  }
}

const update = (object, properties) => {
  return {
    ...object,
    ...properties
  };
};

const forceOnChange = (input, value) => {
  const previousValue = input.value;

  // eslint-disable-next-line no-param-reassign
  input.value = value;

  const tracker = input._valueTracker;
  if (tracker) {
    tracker.setValue(previousValue);
  }

  // 'change' instead of 'input', see https://github.com/facebook/react/issues/11488#issuecomment-381590324
  input.dispatchEvent(new Event('change', { bubbles: true }));
}

const mergeTags = (options, selected) => {
  let merged = options;
  if(selected && selected !== '') {
    if(!options.includes(selected)) {
      merged = [selected, ...options];
    }
  }
  return merged;
}

const mergeAllTags = (options, selected) => {
  let merged = options;
  if(selected && selected.length > 0) {
      merged = [...new Set([...selected, ...options])];
  }
  return merged;
}

export {
    // validation utility methods
    verifyEmail,
    verifyLength,
    verifyNumber,
    verifyRatio,
    verifyPhone,
    verifyMobile,
    verifyPassword,
    verifyPasswordEquals,
    verifyUrl,
    verifyWebsite,

    mergeTags,
    mergeAllTags,
    makeLink,
    makePartnerIcon,
    makeSupervisorIcon,
    makePrimaryContact,
    makeProposalUnitAddAction,
    makeProposalUnitRemoveAction,
    makeProposalUnitViewCommentAction,
    makeInteractionTypeColor,
    makeInteractionTypeIcon,
    makeProposalUnitIcon,
    makeProposalStatusIcon,
    forceOnChange,
    update,
    updatestate,
    updateCustomAttribute,
};
