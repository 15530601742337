import React, { Component } from 'react';
import { connect } from 'react-redux';

// core components
import CardHeader from 'components/Card/CardHeader';
import CardText from 'components/Card/CardText';

// echelon components
import Proposal from "./Proposal";
import Portrait from './Portrait/Portrait';
import Compare from './Compare/Compare';
import CommentsDialog from 'views/Components/Comments/CommentsDialog';

// common components
import * as proposalunit from "store/actions/proposalunit";
import { updatestate } from 'variables/utilities';

function CommentsPopup (props) {
  const { action, title, autoreply, comments, showComments, 
          onSendComment, onClose } = props;

  if(showComments === false) {
    return <></>
  }

  return (
    <CommentsDialog
      title={title}
      open={showComments}
      comments={comments}
      autoreply={autoreply}
      action={action}
      onSendComment={onSendComment}
      onClose={onClose}
    />
  );
}

function ComparePage (props) {
  return (
    <Proposal 
      Component={Compare}
      ComponentProps={{...props}}
    />
  );
}

function PortraitPage (props) {
  const { proposal } = props;
  const artifactsorder = proposal && proposal.traits ? proposal.traits.artifactsorder : []
  return (
    <Proposal 
      Component={Portrait}
      ComponentProps={{...props, artifactsorder: artifactsorder}}
    />
  );
}

class Proffer extends Component {
  constructor(props) {
    super();
    this.state = {
      autoreply: false,
      title: '', 
      action: 'None',
      comments: [], 
      showComments: false,      

      unit: {},
      
      // status updates
      unitstatus: {},
      unitstatusModifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { proposalunit } = props;
    if(proposalunit && proposalunit.modifiedDate > state.unitstatusModifiedDate) {
      return {
        showComments: false,

        unitstatus: {...state.unitstatus, [proposalunit.unitId]: proposalunit},
        unitstatusModifiedDate: proposalunit.modifiedDate,
      }
    }
    return null;
  }

  onChange = (event, attribute, type, valid=true) => {
    console.log("onChange", event, attribute, type);
    const updated = updatestate(this.state, event, attribute, type, valid);
    this.setState(updated);
  }

  onSetFilters = (values, type) => {
    console.log("onSetFilters", values, type);
    this.setState({[type]: values, start: 0});
  }

  onClickActions = (unitId, unit, title, action, comments, autoreply) => {
    const { proposal } = this.props; 
    this.setState({ unitId: unitId, unit: unit, comments: comments, 
                    autoreply: autoreply, title: title, action: action,
                    showComments: true, proposalId: proposal.proposalId }
                  );
  }

  onPostAction = (action, comment) => {
    const { proposalId, unit } = this.state;
    const traits = {
      status: action,
      comment: comment
    };
    
    this.props.update(proposalId, unit.unitId, traits);
  }

  render() {
    const { code, access, filter, proposal } = this.props;
    const { compare=false } = filter;

    // now return the UI that needs to be rendered
    return (
      <div>
        <CommentsPopup
          {...this.state}
          onSendComment={this.onPostAction}
          onClose={()=>this.setState({showComments: false})}
        />
        <CardHeader centered plain>
          <CardText proposal>
            <h3><small>{proposal && proposal.details ? proposal.details.title : ''}</small></h3>
          </CardText>
        </CardHeader>
        {compare === true &&
        (<ComparePage
          {...this.state}
          code={code}
          access={access}
          proposal={proposal}
          filter={filter}
          unitstatus={this.state.unitstatus}
          onChange={this.onChange}
          onSetFilters={this.onSetFilters}
          onClickActions={this.onClickActions}
        />)
        }
        {compare === false &&
        (<PortraitPage
          code={code}
          access={access}
          proposal={proposal}
          filter={filter}
          unitstatus={this.state.unitstatus}
          onChange={this.onChange}
          onSetFilters={this.onSetFilters}
          onClickActions={this.onClickActions}
        />)
        }
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    code: state.authorize.code,
    settings: state.settings.settings,
    proposal: state.proposal.proposal,
    proposalunit: state.proposalunit.unit,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    update: (proposalId, unitId, body) => dispatch(proposalunit.update(proposalId, unitId, body)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Proffer);
