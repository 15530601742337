import {
  transition,
  containerFluid,
  whiteColor,
  blackColor,
} from "assets/jss/material-dashboard-pro-react.js";

const clientStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh",
    "&:after": {
      display: "table",
      clear: "both",
      content: '" "'
    },
  },
  mainPanel: {
    transitionProperty: "top, bottom, width",
    transitionDuration: ".2s, .2s, .35s",
    transitionTimingFunction: "linear, linear, ease",
    [theme.breakpoints.up("md")]: {
      width: "100%"
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch",
  },
  optionsDrawer: {
    marginTop: "64px",
    marginRight: "16px",
    height: "100% !important",
    border: "0",
    borderRadius: "4px",
    width: "27% !important",
  },
  optionsDrawerMobile: {
    marginTop: "64px",
    marginRight: "2px",
    height: "100% !important",
    border: "0",
    borderRadius: "4px",
  },
  optionsContent: {
    padding: "15px 3px",
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  businessDrawer: {
    marginTop: "64px",
    marginRight: "16px",
    height: "100% !important",
    border: "0",
    borderRadius: "4px",
    width: "30% !important",
  },
  menuDrawer: {
    marginTop: "0px",
    width: "19% !important",
  },
  contentDrawer: {
    marginTop: "64px",
    width: "70% !important",
  },
  documentDrawer: {
    marginTop: "64px",
    width: "40% !important",
  },
  fullDrawer: {
    marginTop: "64px",
    marginBottom: "4px",
    width: "100% !important",
  },
  content: {
    padding: "15px 15px",
    minHeight: "calc(100vh - 123px)",
  },
  panelContent: {
    padding: "15px 25px",
  },
  fullHeightPanelContent: {
    padding: "15px 25px",
    height: "100% !important",
  },
  container: { ...containerFluid },
  mainPanelWithPerfectScrollbar: {
    overflow: "hidden !important"
  },
  blackBackground: {
    color: whiteColor,
    "&:after": {
      background: blackColor,
      opacity: ".8"
    }
  },
});

export default clientStyle;
