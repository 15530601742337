import React from "react";

// @material-ui/icons

// @material-ui/core components

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";

// custom components
import Dropdown from "views/Components/Dropdown";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

import { verifyNumber } from "variables/utilities";

export default function OfficeRequirementView(props) {
  const { valid, specification,
          onChangeHandler } = props;

  return (
    <div>
      <GridContainer>
        {/* Location preference & area */}
        <GridItem xs={12} sm={12} md={6}>
          <Dropdown
            id="required-space-type"
            title="Type of space"
            options={specification.spacetype.length > 0 ? specification.spacetype : []}
            value={specification.spacetype.length > 0 ? specification.spacetype : []}
            onChange={(event) => {
              onChangeHandler(event, 'spacetype', 'specification', true);
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Workstations"
            id="required-workstatation"
            error={valid.workstatations === false}
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => {
                const valid = verifyNumber(event.target.value);
                onChangeHandler(event, 'workstatations', 'specification', valid);
              },
              value: specification.workstatations,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={3}>
          <CustomInput
            labelText="Cabins"
            id="required-cabins"
            error={valid.cabins === false}
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => {
                const valid = verifyNumber(event.target.value);
                onChangeHandler(event, 'cabins', 'specification', valid);
              },
              value: specification.cabins,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Meeting rooms"
            id="required-meetingrooms"
            error={valid.meetingrooms === false}
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => {
                const valid = verifyNumber(event.target.value);
                onChangeHandler(event, 'meetingrooms', 'specification', valid);
              },
              value: specification.meetingrooms,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Conference rooms"
            id="required-conferencerooms"
            error={valid.conferencerooms === false}
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => {
                const valid = verifyNumber(event.target.value);
                onChangeHandler(event, 'conferencerooms', 'specification', valid);
              },
              value: specification.conferencerooms,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={4}>
          <CustomInput
            labelText="Server rooms"
            id="required-serverrooms"
            error={valid.serverrooms === false}
            formControlProps={{
              fullWidth: true,
              required: false
            }}
            inputProps={{
              onChange: event => {
                const valid = verifyNumber(event.target.value);
                onChangeHandler(event, 'serverrooms', 'specification', valid);
              },
              value: specification.serverrooms,
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-utilities"
            labelText="Utilities"
            clearText=""
            placeholder=""
            options={specification.infra ? specification.infra : []}
            value={specification.infra ? specification.infra : []}
            error={valid.infra === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'infra', 'specification', true);
            }}
          />
        </GridItem>	

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-amentities"
            labelText="Amenities"
            clearText=""
            placeholder=""
            options={specification.amenities ? specification.amenities : []}
            value={specification.amenities ? specification.amenities : []}
            error={valid.amenities === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'amenities', 'specification', true);
            }}
          />
        </GridItem>	


      </GridContainer>
    </div>
  )
}