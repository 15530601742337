const messages = {

  // client relevant codes
  "004": "The proposal is no longer available, please reach out to your broker for more details.",
  "006": "The proposal is no longer available, please reach out to your broker for more details.",

  // "999": "One or more values have not been provided or are not correct. Check fields marked with red and try the request again",
  "000": "We have encountered a problem while accessing the server, please try after some time",
  // "002": "All mandatory values have not been provided; backend mandatory parameter check failed",
  // "003": "The request cannot be performed in the current state; Check the details and retry the request",   
  // "007": "The password does not does not match the password rules. Your password must be at least 6 characters long with at least one numeric, one lower case and upper case character",
  // "008": "User with the same emailId already exists, use a different emailId and try again",
  // "502": "Check your username and password. If you still cannot log in, you can reset your password and try again",
  // "503": "You have no password. You can reset your password, using the forgot password, and try again",
  "504": "The proposal is no longer available, please reach out to your broker for more details.",

  // "otp": "A one-time passcode has been sent to your emailid, enter the code to procced with the password update",
  // "password": "Your password has been reset successfully. You must use the new password the next time you login",
  // "file1mb": "The file you are trying to attach is larger than 1 MB. Choose a file smaller than 1 MB",
  // "file5mb": "The file you are trying to attach is larger than 5 MB. Choose a file smaller than 5 MB",
  // "file10mb": "The file you are trying to attach is larger than 10 MB. Choose a file smaller than 10 MB",

  // success message for the respective entities
  // "user": "Your profile has been saved successfully",
  // "personalization": "Your personalization settings has been saved successfully",
  // "business": "Your profile has been saved successfully",
  // "Landlord": "Your landlord has been saved successfully",
  // "Client": "Your client has been saved successfully",
  // "contact": "Your contact has been saved successfully",
  // "building": "Your building information has been saved successfully",
  // "employee": "Your employee information has been saved successfully",
  // "interaction": "Your interaction information has been saved successfully",
  // "attachment": "Your attachment/asset has been saved successfully",
  // "tenant": "Your tenant has been saved successfully",
  // "requirement": "Your requirement has been saved successfully",
  // "unit": "Your unit has been saved successfully",
  // "proposal": "Your proposal has been saved successfully",
  // "proposalshared": "Your proposal has been shared successfully",
  // "addproposalunit": "Unit has been added to the proposal",
  // "proposalunit": "Your proposal unit has been saved successfully",
  // "proposalunitrefresh": "Your proposal unit information has been refreshed successfully",
  // "comment": "Your comments have been saved successfully",
  
  // "deleteLandlord": "Landlord has been removed successfully",
  // "deleteClient": "Client has been removed successfully",
  // "deletecontact": "Contact has been removed successfully",
  // "deletebuilding": "Building has been removed successfully",
  // "deleteemployee": "Employee has been removed successfully",
  // "deleteinteraction": "Interaction has been removed successfully",
  // "deletetenant": "Tenant has been removed successfully",
  // "deleterequirement": "Requirement has been removed successfully",
  // "deleteunit": "Unit has been removed successfully",
  // "deleteproposal": "Proposal has been removed successfully",
 
  // "NOTACTIVE": "Your corporate account has not been activated, contact Echelon to activate your account",
  "logout": "Your session has ended and you have been logged out",

  "retrieving": "We are busy retrieving the information about the proposal for your perusal",
  "noaccess": "Access to the proposal has been restricted, please reach out to your broker for more details.",
  "nounits": "All proposed units have been filtered. Adjust criteria to see proposed units",
  "norequirements": "No requirements found for the proposal, please reach out to your broker for more details.",

}

export {
  messages
}