import React from "react";

// @material-ui/core components
import { FormControlLabel, Switch } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/customSwitchStyle.js";
const useStyles = makeStyles(styles);

export default function CustomSwitch(props) {
  const classes = useStyles();
  const { label, checked, onChange } = props;
  return (
    <FormControlLabel
      control={
        <Switch
          checked={checked}
          onChange={onChange}
          classes={{
            root: classes.root,
            switchBase: classes.switchBase,
            checked: classes.switchChecked,
            thumb: classes.switchIcon,
            track: classes.switchBar
          }}
        />
      }
      classes={{
        label: classes.label + " " + classes.root
      }}
      labelPlacement="end"
      label={label}
    />
  );
}