import React from "react";

// non standard imports
import cx from "classnames";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Drawer, } from "@material-ui/core";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";

// import image1 from "assets/img/sidebar-1.jpg";
import image2 from "assets/img/sidebar-2.jpg";

// echelon components
import * as routes from "routes/clientRoutes";

import styles from "assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";
const useStyles = makeStyles(styles);

var ps;
class SidebarWrapper extends React.Component {
  sidebarWrapper = React.createRef();
  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.sidebarWrapper.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }
  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  render() {
    const { className, user, headerLinks, links } = this.props;
    return (
      <div className={className} ref={this.sidebarWrapper}>
        {user}
        {headerLinks}
        {links}
      </div>
    );
  }
}

export default function NavigationMenuView(props) {
  const classes = useStyles();
  const { brandText, bgColor="black", color="white", paperPropsClassName, 
          rtlActive, logo, drawer, setDrawer, requirements,
  } = props;

  const logoNormal =
  classes.logoNormal +
  " " +
  cx({
    [classes.logoNormalSidebarMini]: props.miniActive,
    [classes.logoNormalSidebarMiniRTL]: rtlActive && props.miniActive,
    [classes.logoNormalRTL]: rtlActive
  });
const logoMini =
  classes.logoMini +
  " " +
  cx({
    [classes.logoMiniRTL]: rtlActive
  });
const logoClasses =
  classes.logo +
  " " +
  cx({
    [classes.whiteAfter]: bgColor === "white"
  });
  var brand = (
  <div className={logoClasses}>
    <a
      href="#home"
      className={logoMini}
    >
      <img src={logo} alt="logo" className={classes.img} />
    </a>
    <a
      href="#home"
      className={logoNormal}
    >
      {brandText}
    </a>
  </div>
  );

  const drawerPaper =
    classes.drawerPaper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive,
  });

  const sidebarWrapper =
    classes.sidebarWrapper +
    " " +
    cx({
      [classes.drawerPaperMini]: props.miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf("Win") > -1
  });

  // verifies if routeName is the one active (in browser input)
  const activeRoute = routeName => {
    return window.location.href.indexOf(routeName) > -1 ? "active" : "";
  };

  const createLinks = routes => {
    return routes.map((route, key) => {

      const disabled = route.drawer === 'requirements' 
            && (requirements === null || requirements === undefined);
      const innerNavLinkClasses =
        classes.collapseItemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(route.path)
      });
      const navLinkClasses =
        classes.itemLink +
        " " +
        cx({
          [" " + classes[color]]: activeRoute(route.path)
      });
      const itemText =
        classes.itemText +
        " " +
        cx({
          [classes.itemTextMini]: props.miniActive,
          [classes.itemTextMiniRTL]: rtlActive && props.miniActive,
          [classes.itemTextRTL]: rtlActive
        });
      const collapseItemText =
        classes.collapseItemText +
        " " +
        cx({
          [classes.collapseItemTextMini]: props.miniActive,
          [classes.collapseItemTextMiniRTL]: rtlActive && props.miniActive,
          [classes.collapseItemTextRTL]: rtlActive
        });
      const itemIcon =
        classes.itemIcon +
        " " +
        cx({
          [classes.itemIconRTL]: rtlActive
        });

      return (
        <ListItem
          key={key}
          className={cx(
            { [classes.item]: route.icon !== undefined },
            { [classes.collapseItem]: route.icon === undefined }
          )}
          disabled={disabled}
        >
          <NavLink
            to={window.location.pathname + route.path}
            className={cx(
              { [navLinkClasses]: route.icon !== undefined },
              { [innerNavLinkClasses]: route.icon === undefined }
            )}
            onClick={(event) => {
              event.preventDefault();
              if(!disabled) {
                setDrawer(route.drawer);
              }
            }}
          >
            {route.icon !== undefined ? (
                typeof route.icon === "string" ? (
                  <Icon className={itemIcon}>{route.icon}</Icon>
                ) : (
                  <route.icon className={itemIcon} />
                )
              ) : null
            }
            <ListItemText
              primary={route.menu}
              disabled={disabled}
              disableTypography={true}
              className={cx(
                { [itemText]: route.icon !== undefined },
                { [collapseItemText]: route.icon === undefined }
              )}
            />
          </NavLink>

      </ListItem>
      );
    });
  }

  var links = (
    <List className={classes.list}>{createLinks(routes.menuroutes)}</List>
  );

  /* Navigation panel / drawer */
  return (
    <Drawer anchor={"left"} open={drawer === 'navigation'}
            onClose={() => setDrawer(null)}
            classes={{
              paper: drawerPaper + " " + classes[bgColor + "Background"]
            }}    
            PaperProps={{className: paperPropsClassName}}>
      {brand}
      <SidebarWrapper
        className={sidebarWrapper}
        links={links}
      />
      {image2 !== undefined ? (
          <div
            className={classes.background}
            style={{ backgroundImage: "url(" + image2 + ")" }}
          />
        ) : null}
    </Drawer>
  );
}
