import axios from "store/axios-broker";
import * as types from 'store/types';

const started = (request) => {
	return {
		type: types.PROPOSAL_HISTORY_START,
		request: request
	};
};

const succeeded = (histories, history, index=undefined) => {
	return {
		type: types.PROPOSAL_HISTORY_SUCCESS,
    histories: histories,
		history: history,
		index: index,
	};
};

const failed = (error) => {
	return {
		type: types.PROPOSAL_HISTORY_FAILURE,
		error: error && error.data && error.data.code ? error.data.code : "000"
	};
};

export const retrieve = (proposalId) => {
	return dispatch => {
		dispatch(started('retrieve'));
		let url = `/apex/consumer/${proposalId}/proposal/history`;
		axios.get(url).then(response => {
			dispatch(succeeded(response.data, null));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};
