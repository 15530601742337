import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null,
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null
	});
};

const succeeded = (state, action) => {
	const units = action.units ? action.units : state.units;
	if(action.unit && action.unit.unitId) {
		const { unitId } = action.unit;
		const index = Object.keys(units).filter((key) => {
			return units[key].unitId === unitId
		});

		if(index.length > 0) {
			units[index[0]]= { ...action.unit, traits: units[index[0]].traits };
		}
	}

	return update( state, {
		units: units,
		unit: action.unit,
		modifiedDate: new Date(),
	});
};

const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const proposalunits = (state = initial, action) => {
	switch ( action.type ) {
		case types.PROPOSAL_UNIT_START: 
			return started(state, action);

		case types.PROPOSAL_UNIT_SUCCESS: 
			return succeeded(state, action);

		case types.PROPOSAL_UNIT_FAILURE: 
			return failed(state, action);

		default:
			return state;
	}
};

export default proposalunits;