import React, { Component } from 'react';
import { connect } from 'react-redux';
import OptionsShareDialog from './Sections/OptionShareDialog';

// core components
// import GridContainer from 'components/Grid/GridContainer';
// import GridItem from 'components/Grid/GridItem';

// echelon components
import OptionsView from './OptionsView';

import * as proposal from "store/actions/proposal";
import * as personalization from 'store/actions/personalization';

class Options extends Component {
  constructor(props) {
    super();
    this.state = {
     
      options: {
        filters: [],
        classification: [],
        furnishing: [],
        unitNumbers: [],
      },

      proposalinfo: {
        proposal: {},
        proposalId: null,
        proposalModifiedDate: -1,
      },

      units: [],
      unitsLoadedDate: -1,
      
      showShareDialog: false,
      compactView: false,
      compareView: true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    const { proposal, units, unitsLoadedDate } = props;

    var { proposalinfo, showShareDialog, compactView, compareView } = state;
    // proposal information
    if(proposal && proposal.modifiedDate > proposalinfo.proposalModifiedDate) {
      const { configuration } = proposal.traits;
      showShareDialog = false;
      compactView = configuration && configuration.compact ? configuration.compact : false;
      compareView = configuration && configuration.compare ? configuration.compare : false;
      proposalinfo = {
        ...proposalinfo,
        proposalId: proposal.proposalId,
        proposal: proposal,
        proposalModifiedDate: proposal.modifiedDate,
      };
    }

    // unit information
    if(units && unitsLoadedDate > state.unitsLoadedDate) {
      const statusFilters = units.map((unit) => unit.status).filter((status) =>  status !== 'Removed');
      const classification = units.map((unit) => unit.traits["Classification"])
                              .filter((classification) => classification && classification !== '');
      const furnishing = units.map((unit) => unit.traits["Furnishing type"])
                            .filter((furnishing) => furnishing && furnishing !== '');
      const unitNumbers = units.map((unit) => unit.traits["Unit number"])
                                .filter((number) => number);

      const filters = [...new Set(statusFilters)].sort((a, b) => a.localeCompare(b));
      const sortedfurnishing = [...new Set(furnishing)].sort((a, b) => a.localeCompare(b));
      const sortedclassification = [...new Set(classification)].sort((a, b) => a.localeCompare(b));
      return {
        options: {
          filters: filters,
          classification: sortedclassification,
          furnishing: sortedfurnishing,
          unitNumbers: unitNumbers.sort((a, b) => a.localeCompare(b)),
        },
        proposalinfo: proposalinfo,
        compactView: compactView,
        compareView: compareView,
        showShareDialog: showShareDialog,
      }
    }

    return {
      proposalinfo: proposalinfo,
      compactView: compactView,
      showShareDialog: showShareDialog,
    };
  }

  onClickShare = () => {
    this.setState({showShareDialog: true});
  }

  onShareProposal = (emailIds, subject, comment) => {
    const { proposalId } = this.state.proposalinfo;
    const traits = {
      emails: emailIds,
      subject: subject,
      comment: comment,
    };

    this.props.forward(proposalId, traits);
  }

  onClickRestart = () => {
    const tourId = localStorage.getItem("navigationTourId");
    if(tourId) {
      this.props.update({[tourId]: 'new'});
      this.props.closeDrawer();
    }
  }

  render() {
    const { options, proposalinfo, showShareDialog, compactView, compareView } = this.state;
    return (
      <div>
        {showShareDialog ? 
        (<OptionsShareDialog 
          id="proposal-share-confirm-dialog"
          name={"my colleagues"}
          open={showShareDialog}
          onClose={() => this.setState({showShareDialog:  false})}
          onShareProposal={this.onShareProposal}
        />): null
        }
        <OptionsView
          proposal={proposalinfo.proposal}
          options={options}
          filter={{compact: compactView, compare: compareView, ...this.props.filter}}
          setFilter={this.props.setFilter}
          onClickShare={this.onClickShare}
          onClickRestart={this.onClickRestart}
        />
      </div>
    );
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    code: state.authorize.code,
    proposal: state.proposal.proposal,
    units: state.proposalunit.units,
    unitsLoadedDate: state.proposalunit.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  const userId = localStorage.getItem("userId");
  return {
    forward: (proposalId, traits) => dispatch(proposal.forward(proposalId, traits)),
    update: (tours) => dispatch(personalization.update('tours', tours, null, userId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Options);
