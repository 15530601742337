import {
  container,
  blackColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";

const artifactCarouselStyle = {
  section: {
    marginTop: "-40px",
    padding: "10px 0"
  },
  container,
  marginAuto: {
    marginLeft: "auto !important",
    marginRight: "auto !important"
  },
  small: {
    maxHeight: "320px",
  },
  medium: {
    maxHeight: "480px",
  },
  large: {
    maxHeight: "6400px",
  },
  cardHeader: {
    padding: "0.75rem 1.25rem",
    marginBottom: "0",
    borderBottom: "none",
    background: "transparent",
    zIndex: "3 !important",
    "&:first-child": {
      borderRadius: "calc(.25rem - 1px) calc(.25rem - 1px) 0 0"
    },
    "&$cardHeaderImage": {
      marginLeft: "18px",
      marginRight: "18px",
      marginTop: "30px",
      borderRadius: "6px"
    },
  },
  cardHeaderImage: {
    position: "relative",
    padding: "0",
    zIndex: "1",
    "& img": {
      width: "100%",
      borderRadius: "6px",
      pointerEvents: "none",
      boxShadow:
        "0 5px 15px -8px rgba(" +
        hexToRgb(blackColor) +
        ", 0.24), 0 8px 10px -5px rgba(" +
        hexToRgb(blackColor) +
        ", 0.2)"
    },
    "& a": {
      display: "block"
    }
  },
};

export default artifactCarouselStyle;
