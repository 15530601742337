import React, { Component } from 'react';
import { connect } from 'react-redux';
import History from './History/History';

class Units extends Component {
  constructor(props) {
    super();
    this.state = {
      unit: null,
      allunits: {},
      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { proposal, units, modifiedDate } = props;
    if(units && modifiedDate > state.modifiedDate) {
      var { unitsorder=[] } = proposal.traits;
      if(unitsorder === null) {
        unitsorder = [];
      }

      const withchildren = units.reduce((previous, current) => {
        const { unitId, traits } = current;
        const childunits = traits["Child units"];
        if(childunits) {
          const children = childunits.reduce((previous, current) => {
            return { ...previous, [current.unitId]: current }}, {}
          );
          return { ...previous, [unitId]: current, ...children };
        }

        return { ...previous, [unitId]: current };
      }, {});

      return {
        units: units.sort((a, b) => unitsorder.indexOf(a.unitId) - unitsorder.indexOf(b.unitId)),
        allunits: withchildren,
        modifiedDate: modifiedDate,
      }
    }
   
    return null;
  }

  onClickActions = (unitId, index, actionKey, title, comments) => {
    const { allunits } = this.state;
    const actionMapping = ['Clarification', 'Accepted', 'Rejected', ''];
    this.props.onClickActions(unitId, allunits[unitId], title, 
                              actionMapping[actionKey], comments, actionKey !== 4)
  }

  render() {
    const { modifiedDate, ...rest } = this.state; 
    return ( 
      <History
        {...this.props}
        {...rest}
        unitsLoadedDate={modifiedDate}
        onClickActions={this.onClickActions}
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    units: state.proposalunit.units,
    modifiedDate: state.proposalunit.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Units);
