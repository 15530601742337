import React from "react";
import PropTypes from "prop-types";

import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import AutocompleteEntity from "./AutocompleteEntity";

const filter = createFilterOptions();
export default function AutocompleteTags(props) {
  const {labelText, limitTags=-1, clearText="Remove all tags", options, multiple, ...rest } = props
  const reasons = ['select-option', 'blur'];
  return (
    <AutocompleteEntity 
      {...rest}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple={multiple}
      limitTags={limitTags}
      labelText={labelText}
      clearText={clearText}
      options={options}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        // Suggest the creation of a new value
        if (params.inputValue !== '') {
          filtered.push({
            inputValue: params.inputValue,
            name: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      onChange={(event, tags, reason)=>{
        let filtered = tags;
        if(reasons.includes(reason)) {
          if(multiple === false) {
            filtered = tags.inputValue ? tags.inputValue : tags;
          } else {
            filtered = tags.map((tag) => {
              return tag.inputValue ? tag.inputValue : tag;
            });
          }
        }
        props.onChange(event, filtered, reason);
      }}
    />
  );
}

AutocompleteTags.propTypes = {
  options: PropTypes.array.isRequired,
  labelText: PropTypes.string,
  clearText: PropTypes.string,
  limitTags: PropTypes.number,
}