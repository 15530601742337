import React from "react";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";

import styles from "assets/jss/material-dashboard-pro-react/components/customInputStyle.js";
const useStyles = makeStyles(styles);

export default function NumberInput(props) {
  const classes = useStyles();
  const {
    id,
    inputProps,
    error,
    white,
    inputRootCustomClasses,
    success,
    alignright,
    ...rest
  } = props;

  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
    [classes.alignRight]: alignright,
  });
  let newInputProps = {
    maxLength:
      inputProps && inputProps.maxLength ? inputProps.maxLength : undefined,
    minLength:
      inputProps && inputProps.minLength ? inputProps.minLength : undefined
  };
  return (
      <Input
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        id={id}
        {...rest}
        inputProps={newInputProps}
      />
  )
}
