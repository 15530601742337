import * as types from 'store/types';
import { update } from 'variables/utilities';

const initial = {
	error: null, request: null, entities: {},
	loading: false, artifact: null,
};

const started = (state, action) => {
	return update( state, {
		request: action.request,
		error: null, loading: true
	});
};

const succeeded = (state, action) => {
	return update( state, {
		artifact: action.artifact,
		entities: {...state.entities, ...action.entities},
		loading: false, notify: true
	});
};

const removed = (state, action) => {
	return update( state, {
		artifact: null,
		error: null, loading: false
	});
}


const failed = (state, action) => {
	return update( state, {
		error: action.error, loading: false
	});
}

const artifact = (state = initial, action) => {
	switch ( action.type ) {
		case types.ARTIFACT_START: 
			return started(state, action);

		case types.ARTIFACT_SUCCESS: 
			return succeeded(state, action);

		case types.ARTIFACT_FAILURE: 
			return failed(state, action);

		case types.ARTIFACT_REMOVE: 
			return removed(state, action);

		default:
			return state;
	}
};

export default artifact;