import axios from 'axios';

const instance = axios.create({
	baseURL: process.env.REACT_APP_API_ENDPOINT
});

instance.interceptors.request.use((config) => {
	// add the content type header
	let contentType = 'application/json';
	if(config.urlencoded) {
		contentType = 'application/x-www-form-urlencoded';
	}
	if(config.multipart) {
		contentType = 'multipart/form-data';
	}
	config.headers['Content-Type'] = contentType;

	// add the bearer token, if present																											
	const token = localStorage.getItem("token");
	if(token && token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	config.headers['Xspaces-App'] = 'ecp';
	return config;
}, error => {
	return Promise.reject(error);
});

export default instance;