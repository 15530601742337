import React from "react";
import Datetime from "react-datetime";
import PropTypes from "prop-types";
import classNames from "classnames";

// @material-ui icons
import RemoveIcon from "@material-ui/icons/Clear";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { InputAdornment, IconButton, Input } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";

import styles from "assets/jss/material-dashboard-pro-react/views/customDatetimeStyle";
const useStyles = makeStyles(styles);

export default function CustomDatetime(props) {
  const classes = useStyles();

  const { error, success, readOnly=false, id, compact, labelText, dateFormat, timeFormat, white,
          onChange, value, labelProps, closeOnSelect, formControlProps, inputRootCustomClasses } = props;
 
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
        
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
    [classes.labelRootCompact]: compact,
  });

  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames({
      [formControlProps.className]: true,
      [classes.formControl]: true,
      [classes.formControlCompact]: compact,
    });
  } else {
    formControlClasses = classes.formControl;
  }

  const renderInput = (props, openCalendar) => {
    return (
      <Input {...props} 
        endAdornment={readOnly ? 
          (<InputAdornment position="end">
            <IconButton
              size="small"
              aria-label="remove selected"
              onClick={() => onChange('')}
              edge="end">
              <RemoveIcon fontSize='small' />
            </IconButton>
          </InputAdornment>
          ) :  null}
        classes={{
          input: inputClasses,
          root: marginTop,
          disabled: classes.disabled,
          underline: underlineClasses
        }}
        onFocus={()=>openCalendar()}
        fullWidth />
    );
  }

  return (
    <FormControl {...formControlProps} className={formControlClasses}>
      {labelText !== undefined ? (
        <InputLabel
          className={classes.labelRoot + " " + labelClasses}
          htmlFor={id}
          {...labelProps}
        >
          {labelText}
        </InputLabel>
      ) : null}
      <Datetime
        id={id}
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        readOnly={true}
        closeOnSelect={closeOnSelect}
        value={value}
        renderInput={(props, openCalendar) => renderInput(props, openCalendar)}
        inputProps={{readOnly: readOnly, }}
        onChange={(date) => onChange(date)}
      />
    </FormControl>
  )
}

CustomDatetime.defaultProps = {
  closeOnSelect: false,
  dateFormat: "YYYY",
}

CustomDatetime.propTypes = {
  error: PropTypes.bool,
  success: PropTypes.bool,
  readOnly: PropTypes.bool,
  dateFormat: PropTypes.any,
  timeFormat: PropTypes.any,
  closeOnSelect: PropTypes.bool,
  labelText: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
}
