import React, { Component } from 'react';
import { connect } from 'react-redux';

// echelon components
import ArtifactCarouselView from './ArtifactCarouselView';

class ArtifactCarousel extends Component {
  constructor(props) {
    super();

    this.state = {

      images: [],
      modifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { artifacts, modifiedDate } = props;

    if(artifacts && modifiedDate > state.modifiedDate) {
      const imageList = artifacts.filter((artifact) => artifact.traits.kind === 'asset')
        .map((artifact) => {
          const { dossierId, ownerId, traits } = artifact;
          return ([
            traits.title,
            `/apex/porter/${dossierId}/document?xspaces-app=ecp`,
            traits.original ? traits.original.ownerId : ownerId,
            dossierId
          ]);
      });

      return ({
        images: imageList,
        modifiedDate: modifiedDate,
      })
    }

    return null;
  }

  render() {
    const { images } = this.state;
    var { artifactsorder=[], slides, height } = this.props;
    if(artifactsorder === null) {
      artifactsorder = [];
    }
    const filteredImages = images.filter((image) => this.props.filterIds.includes(image[2]))
                                  .sort((a, b) => {
                                    return artifactsorder.indexOf(a[3]) - artifactsorder.indexOf(b[3]);
                                  });
    return (
      <ArtifactCarouselView
        height={height}
        slides={slides}
        artifacts={filteredImages}
      />
    )
  }
}

const mapStateToProps = (state, props) => {
	return {
    artifacts: state.artifact.entities['proposal'],
    modifiedDate: state.artifact.entities['proposalmodifiedDate'],
	};
};

const mapDispatchToProps = (dispatch, props) => {
	return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ArtifactCarousel);
