import React from "react";

// @material-ui/icons

// @material-ui/core components

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// custom components
import Dropdown from "views/Components/Dropdown";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

export default function RetailRequirementView(props) {
  const { valid, specification,
          onChangeHandler } = props;

  return (
    <div>
      <GridContainer>
        {/* Location preference & area */}
        <GridItem xs={12} sm={12} md={6}>
          <Dropdown
            id="required-space-type"
            title="Type of space"
            options={specification.spacetype.length > 0 ? specification.spacetype : []}
            value={specification.spacetype.length > 0 ? specification.spacetype : []}
            onChange={(event) => {
              onChangeHandler(event, 'spacetype', 'specification', true);
            }}
          />
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-utilities"
            labelText="Utilities"
            clearText=""
            placeholder=""
            options={specification.infra ? specification.infra : []}
            value={specification.infra ? specification.infra : []}
            error={valid.infra === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'infra', 'specification', true);
            }}
          />
        </GridItem>	

        <GridItem xs={12} sm={12} md={12}>
          <AutocompleteTags
            id="requirements-amenities"
            labelText="Amenities"
            clearText=""
            placeholder=""
            options={specification.amenities ? specification.amenities : []}
            value={specification.amenities ? specification.amenities : []}
            error={valid.amenities === false}
            onChange={(event, tags, reason) => {
              onChangeHandler(tags, 'amenities', 'specification', true);
            }}
          />
        </GridItem>	


      </GridContainer>
    </div>
  )
}