/*eslint-disable*/
import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Slide from "@material-ui/core/Slide";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

// @material-ui icons
import CloseIcon from "@material-ui/icons/Close";
import SendIcon from "@material-ui/icons/Send";

// core components
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput";

// echelon components
import CustomSwitch from "views/Components/CustomSwitch";

import styles from "assets/jss/material-dashboard-pro-react/views/notificationsStyle.js";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";
import { verifyEmail } from "variables/utilities";
const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function OptionsShareDialog(props) {
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const { id, maxWidth="sm", open=false, name, title="Share proposal with ", onClose, onShareProposal} = props;

  const [compact, setCompact] = React.useState(maxWidth === "sm");
  const [valid, setValid] = React.useState(true);
  const [emailIds, setEmailIds] = React.useState([]);
  const [subject, setSubject] = React.useState("");
  const [comment, setComment] = React.useState("");

  const validateAndSetEmailIds = (emailIds) => {
    setValid(true);
    emailIds.map((emailId) => {
      if(!verifyEmail(emailId)) {
        setValid(false);
      }
      return emailId;
    });
    setEmailIds(emailIds);
  }

  return (
    <Dialog
      id={id}
      fullWidth={true}
      maxWidth={compact ? "sm" : "md"}
      scroll={'paper'}
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={onClose}
      aria-labelledby="classic-modal-slide-title"
      aria-describedby="classic-modal-slide-description"
    >
      <DialogTitle
        id="classic-modal-slide-title"
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          justIcon
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          color="transparent"
          onClick={onClose}
        >
          <CloseIcon className={classes.modalClose} />
        </Button>
        <h4 className={classes.modalTitle}>{title + name}</h4>
      </DialogTitle>
      <DialogContent
        dividers={true}
        id="classic-modal-slide-description"
        // className={classes.modalBody}
      >
        <AutocompleteTags 
          id="proposal-share-with-"
          placeholder=""
          autoHighlight
          labelText="Share with my colleagues"
          clearText="Remove all colleagues"
          error={valid === false}
          options={emailIds}
          value={emailIds}
          onChange={(event, emailIds, reason) => validateAndSetEmailIds(emailIds)}
        />
        <CustomInput
          labelText="Message subject"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            value: subject,
            onChange: event => {
              setSubject(event.target.value);
            },
          }}
        />
        
        <CustomInput
          labelText="Message to your colleagues"
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            multiline: true,
            rows:10,
            value: comment,
            onChange: event => {
              setComment(event.target.value);
            },
          }}
        />
      </DialogContent>
      <DialogActions>
        <CustomSwitch 
          checked={compact}
          onChange={(event) => {
            setCompact(!compact);
          }}
          label='Compact view'
        />

        <Button
          color="success"
          disabled={comment === "" || subject === "" || valid === false}
          onClick={()=>onShareProposal(emailIds, subject, comment)}
        >
          <SendIcon /> Send
        </Button>
      </DialogActions>
    </Dialog>

  );
}
