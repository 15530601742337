import {
  defaultFont,
  blackColor,
  dangerColor,
  successColor,
  // primaryColor,
  grayColor,
  hexToRgb
} from "assets/jss/material-dashboard-pro-react.js";
import customCheckboxRadioSwitch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

const customCheckboxStyle = {
  ...customCheckboxRadioSwitch,
  formControl: {
    margin: "0 0 17px 0",
    paddingTop: "22px",
    position: "relative",
    verticalAlign: "unset",
    // "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
    //   // color: grayColor[14]
    // }
  },
  formControlLabel: {
    margin: "0 0 17px 0",
    paddingTop: "10px",
    position: "relative",
    verticalAlign: "unset",
    // "& svg,& .fab,& .far,& .fal,& .fas,& .material-icons": {
    //   // color: grayColor[14]
    // }
  },
  label: {
    cursor: "pointer",
    paddingLeft: "0",
    color: "rgba(" + hexToRgb(blackColor) + ", 0.26)",
    fontSize: "14px",
    lineHeight: "1.428571429",
    fontWeight: "400",
    display: "inline-flex"
  },
  labelRoot: {
    ...defaultFont,
    color: grayColor[3] + " !important",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "1.42857",
    top: "10px",
    letterSpacing: "unset",
    // "& + $underline": {
    //   marginTop: "0px"
    // }
  },
  labelRootError: {
    color: dangerColor[0] + " !important"
  },
  labelRootSuccess: {
    color: successColor[0] + " !important"
  },
};

export default customCheckboxStyle;
