import axios from "store/axios-broker";
import * as types from "store/types.js";
import * as proposal from "./proposal";
import * as settings from "./settings";
import * as personalization from "./personalization";
// import * as proposalunit from "./proposalunit";

const started = (request) => {
  return {
      type: types.PROPOSAL_START,
      request: request,
      error: null
  };
};

const succeeded = (proposalId, code, response) => {
  return {
      type: types.AUTHORIZE_SUCCESS,
      proposalId: proposalId,
      code: code,
  };
};

const failed = (error) => {
  return {
      type: types.AUTHORIZE_FAILURE,
      error: error && error.data && error.data.code ? error.data.code : "000",
  };
};

const querystring = require('querystring');
export const authorize = (proposalId, code) => {
  return dispatch => {
    const body = {
      proposalId: proposalId,
      code: code
    };

    dispatch(started('authorize'));
      let url = '/apex/certify/proposal/' + proposalId + "/code";
      axios.post(url, querystring.stringify(body), {urlencoded: true, login: true})
      .then(response => {
        localStorage.setItem("proposalId", proposalId);
        localStorage.setItem("code", code);
        dispatch(succeeded(proposalId, code, response.data));

        // trigger the retrieval of the business settings
        const identity = JSON.parse(response.data.id_token);
        dispatch(settings.retrieve(identity.traits.businessId));

        // store in the local storage
        localStorage.setItem("userId", identity.identifier);
        localStorage.setItem("businessId", identity.traits.businessId);
        localStorage.setItem("businessName", identity.traits.businessName);
        localStorage.setItem("preview", identity.traits.preview);
        localStorage.setItem("features", JSON.stringify(identity.traits.features));

        // retrieve the personalization/prefereces
        dispatch(personalization.retrieve(identity.identifier));
      }).then((response) => {
        // trigger the retrieval of the proposal
        dispatch(proposal.retrieve(proposalId));
      })
      .catch(error => {
        dispatch(failed(error.response));
      });
  };
};