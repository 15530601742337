import axios from "store/axios-broker";
import * as types from "store/types.js";

const started = (request) => {
  return {
      type: types.BUSINESS_START,
      request: request,
      error: null
  };
};

const succeeded = (business) => {
  return {
      type: types.BUSINESS_SUCCESS,
      business: business,
  };
};

const failed = (error) => {
  return {
      type: types.BUSINESS_FAILURE,
      error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

export const retrieve = (businessId) => {
	return dispatch => {
		dispatch(started('retrieve'));
		let url = "/apex/consumer/" + businessId + "/business";
		axios.get(url).then(response => {
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};