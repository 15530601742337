import React from 'react'
// nodejs library to set properties for components
import PropTypes from "prop-types";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow
} from "react-google-maps";

// custom components
import Card from 'components/Card/Card';
// import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer';

// echelon components
import CustomCheckbox from 'views/Components/CustomCheckbox';
import GridItem from 'components/Grid/GridItem';

function BuildingMarkers (props) {
  const { markers=[], onPageChange } = props;
  return (
    markers.filter((marker) => marker && marker.position.lat && marker.position.lng)
    .map((marker, key) => {
      return (
        <Marker 
          key={key}
          title={marker.name} 
          onClick={() => onPageChange(key+1)}
          position={marker.position}>
            <InfoWindow
              onCloseClick={()=>{}}
              position={marker.position} >
                <div>{marker.name}</div>
            </InfoWindow>
        </Marker>
      )
    })
  );
}

const CustomSkinMap = withScriptjs(
  withGoogleMap((props) => (
    <GoogleMap
      ref={(map) => props.setMap(map)}
      defaultZoom={props.zoom}
      defaultCenter={props.center}
      onZoomChanged={props.onZoomChanged}
      onDragEnd={props.onDragEnd}
      defaultOptions={{
        scrollwheel: false,
        disableDefaultUI: true,
        zoomControl: true,
        fullscreenControl: true,
        styles: [
          {
            featureType: "water",
            stylers: [
              { saturation: 43 },
              { lightness: -11 },
              { hue: "#0088ff" }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: "#ff0000" },
              { saturation: -100 },
              { lightness: 99 }
            ]
          },
          {
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [{ color: "#808080" }, { lightness: 54 }]
          },
          {
            featureType: "landscape.man_made",
            elementType: "geometry.fill",
            stylers: [{ color: "#ece2d9" }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry.fill",
            stylers: [{ color: "#ccdca1" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.fill",
            stylers: [{ color: "#767676" }]
          },
          {
            featureType: "road",
            elementType: "labels.text.stroke",
            stylers: [{ color: "#ffffff" }]
          },
          { featureType: "poi", stylers: [{ visibility: "off" }] },
          {
            featureType: "landscape.natural",
            elementType: "geometry.fill",
            stylers: [{ visibility: "on" }, { color: "#b8cb93" }]
          },
          { featureType: "poi.business", stylers: [{ visibility: "off" }] },
          { featureType: "poi.park", stylers: [{ visibility: "off" }] },
          { featureType: "poi.sports_complex", stylers: [{ visibility: "off" }] },
          { featureType: "poi.medical", stylers: [{ visibility: "off" }] },
        ]
      }}
      options={{     
        disableDefaultUI: true,   
        scrollwheel: false,
        zoomControl: true,
        fullscreenControl: true,
        styles: props.features
      }}
    >
      {props.children}
    </GoogleMap>
  ))
);

function RegularMap(props) {
  const { markers=[], height="medium", zoom=11, center, showfilters=false } = props;
  
  const [heights] = React.useState({small: "320px", medium: "480px", large: "640px"});
  const [features, setFeatures] = React.useState({
    types: [],
    features: [ { featureType: "poi", stylers: [{ visibility: "off" }]}]
  });
  const updateFeatures = (types) => {
    const selectedFeatures = [{ featureType: "poi", stylers: [{ visibility: "off" }] }]; 
    if(types.includes("Business")) {
      selectedFeatures.push({ featureType: "poi.business", stylers: [{ visibility: "simplified" }]});
    }
    if(types.includes("Parks")) {
      selectedFeatures.push({ featureType: "poi.park", stylers: [{ visibility: "on" }]});
    }
    if(types.includes("Hospitals")) {
      selectedFeatures.push({ featureType: "poi.medical", stylers: [{ visibility: "on" }]});
    }
    if(types.includes("Sport centers")) {
      selectedFeatures.push({ featureType: "poi.sports_complex", stylers: [{ visibility: "on" }]});
    }
    setFeatures({types: types, features: selectedFeatures});
  }

  const [map, setMap] = React.useState(null);
  const onZoomChanged = () => {
    if(map && props.onZoomChanged) {
      props.onZoomChanged(map.getZoom());
    }
  }
  const onReposition = () => {
    if(map && props.onReposition) {
      props.onReposition(map.getCenter().toJSON());
    }
  }

  return (
    <Card>
        <CustomSkinMap
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAmYeWcEPsItHJHCwCxZS-DtbM6YSNDBeU"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div
              style={{
                height: heights[height],
                borderRadius: "6px",
                overflow: "hidden"
              }}
            />
          }
          features={features.features}
          zoom={zoom}
          center={center}
          mapElement={<div style={{ height: `100%` }} />}
          onZoomChanged={onZoomChanged}
          onDragEnd={onReposition}
          setMap={setMap}
        >
          <BuildingMarkers markers={markers} onPageChange={props.onPageChange} />
        </CustomSkinMap>
        {showfilters ? 
        (<GridContainer justifyContent="flex-end">
          <GridItem>
            <CustomCheckbox
              name=""
              options={["Business", "Parks", "Hospitals", "Sport centers"]}
              value={features.types} 
              onChange={(event, checked) => updateFeatures(checked)}/>
          </GridItem>
        </GridContainer>) : null
        }
    </Card>
  );
}

RegularMap.propTypes = {
  height: PropTypes.oneOf([
    "small",
    "medium",
    "large",
  ]),
  zoom: PropTypes.number,
  center: PropTypes.object,
}

export default React.memo(RegularMap)