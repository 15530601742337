import React from "react";
import GuidedTour from "./GuidedTour";

// @material-ui/core components
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";

// @material-ui/icons
import AcceptIcon from "@material-ui/icons/ThumbUp";
import RejectIcon from "@material-ui/icons/ThumbDown";
import ActivityIcon from "@material-ui/icons/QuestionAnswer";
import ClarificationIcon from "@material-ui/icons/HeadsetMic";

// custom components
import {primaryColor, infoColor, successColor, dangerColor } 
from "assets/jss/material-dashboard-pro-react.js";

export default function NavigationTour(props) {
  const { targets } = props;

  return (
    <GuidedTour
      tourId={"ecp-navigation-tour-110"}
      steps={[
        {
          title: <strong>Welcome to Exaspaces</strong>,
          content:  <div>Exaspaces is a comprehensive platform for commercial real estate 
                          industry to <strong>Streamline, Optimize and Accelerate</strong> the Brokerage Business.
                      <br></br><br></br>
                      <p>
                        <a href="https://www.youtube.com/watch?v=AkYrOadyens" 
                            rel="noopener noreferrer"
                            target="_blank">Watch this video
                        </a> to learn more about the Exaspaces platform.
                      </p>
                    </div>,
          target: targets.step1,
          placement: "center",
          showProgress: false,
          locale: {next: "TAKE TOUR"}
        },
        {
          title: <strong>Provide your response</strong>,
          content: <div style={{textAlign: "left"}}>Use the actions to perform the following:
                    <List>
                      <ListItem>
                        <ListItemIcon>
                            <ClarificationIcon style={{color: infoColor[0]}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary="Need more information?"
                          secondary="Notify the broker that you would like more information on the proposal option"/>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <AcceptIcon style={{color: successColor[0]}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary="Like what you see?"
                          secondary="Notify the broker that you would like to shortlist the proposal option"/>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <RejectIcon style={{color: dangerColor[0]}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary="Dont like what you see?"
                          secondary="Notify the broker that you do not want the proposal option"/>
                      </ListItem>
                      <ListItem>
                        <ListItemIcon>
                          <ActivityIcon style={{color: primaryColor[0]}}/>
                        </ListItemIcon>
                        <ListItemText 
                          primary="Want to know your interactions?"
                          secondary="View all interactions with the broker"/>
                      </ListItem>
                    </List>
                  </div>,
          target: targets.step4,
          showProgress: false,
          styles: {
            options: {
              width: 480,
            }
          }
        },
        {
          title: <strong>Main navigation menu</strong>,
          content: "You can access the associated requirements, any documentation attached to the proposal or know more about the broker",
          target: targets.step1,
        },
        {
          title: <strong>Options menu</strong>,
          content:  <div style={{textAlign: "left"}}>Use the options menu to do the following actions:
                    <List>
                      <ListItem>
                        <ListItemText 
                          primary="Detail and compare views"
                          secondary="You can switch between the compare view and the current detail view"/>
                      </ListItem>
                      <ListItem>
                        <ListItemText 
                          primary="Share proposal with colleagues"
                          secondary="Allow your colleagues and superiors to view and act on the proposal"/>
                      </ListItem>
                    </List>
                  </div>,
          target: targets.step2,
        },        
        {
          title: <strong>See proposal options</strong>,
          content: "You can see the different proposal options by choosing the appropriate option",
          target: targets.step3,
        },
      ]}
    />
  )
}
