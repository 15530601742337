import React from "react";

// core components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody";
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";

import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";

export default function OptionCompareView(props) {
  const { options, filter, setFilter } = props;

  const unitNumbers = filter.unitNumbers ? filter.unitNumbers : [];

  const onSetFilters = (value, key) => {
    const filters = value.length === 0 ? [] : options.filters;
    setFilter({...filter, [key]: value, furnishing: [],
                          classification: [], filters: filters});
  }

  return (
    <Card>
      <CardBody>
        <GridContainer justifyContent="flex-end">
          <GridItem xs={12} sm={12} md={12}>
            <AutocompleteTags
              id="refine-by-unit-number"
              labelText="Unit numbers"
              clearText="Remove all"
              options={options.unitNumbers ? options.unitNumbers : []}
              onChange={(event, tags, reason) => onSetFilters(tags, 'unitNumbers')}
              value={unitNumbers}
            />
          </GridItem>
        </GridContainer>
      </CardBody>
    </Card>  
  )
}
