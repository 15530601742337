import React from "react";
import { Fragment } from "react";
import moment from 'moment';

// @material-ui/core components
import { SpeedDial, SpeedDialAction } from "@material-ui/lab";
import { makeStyles, Chip, Tooltip, Divider, Badge, InputLabel } from "@material-ui/core";

// @material-ui/icons
import InputAdornment from '@material-ui/core/InputAdornment';
import AcceptIcon from "@material-ui/icons/ThumbUp";
import RejectIcon from "@material-ui/icons/ThumbDown";
import ActivityIcon from "@material-ui/icons/QuestionAnswer";
import ClarificationIcon from "@material-ui/icons/HeadsetMic";
// import DotIcon from "@material-ui/icons/FiberManualRecord";
import SpeedDialIcon from "@material-ui/icons/ViewQuilt";

// core components
import GridContainer from "components/Grid/GridContainer";
import GridItem from "components/Grid/GridItem";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter";
import CardText from "components/Card/CardText";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Table from "components/Table/Table.js";

// echelon components
import PaginationView from "views/Components/Pagination/PaginationView";
import CustomDatetime from "views/Components/CustomDatetime";
import CustomNumber from "views/Components/CustomNumber/CustomNumber";
import AutocompleteTags from "views/Components/Autocomplete/AutocompleteTags";
import ArtifactCarousel from "./ArtifactCarousel";
import RegularMap from "views/Client/Maps/RegularMap";

import { messages } from "variables/messages";

import styles from "assets/jss/material-dashboard-pro-react/views/profferViewStyle.js";
const useStyles = makeStyles(styles);

export default function PortraitView(props) {
  const [open, setOpen] = React.useState({});

  const { rows, messageKey, structure={}, unit, unitstatus, child, childpage, artifactsorder, 
          compact, mapdefaults,
          onClickActions, onPageChange, onChildPageChange } = props;
  const classes = useStyles();

  const makeDateField = (name, format, date, key, attribute) => {
    const { columns, label=true } = attribute;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <CustomDatetime 
          id={"makeDateField-" + name}
          labelText={label === false ? "" : name}
          compact={compact}
          dateFormat={format ? format : "MMMM YYYY"}
          timeFormat={false}
          readOnly={true}
          formControlProps={{
            fullWidth: true,
          }}
          value={date ? new Date(date) : null}
          onChange={() => {}}
          disabled
        />
      </GridItem>
    );
  }

  const makeTimeField = (name, format, date, key, attribute) => {
    const { columns, label=true } = attribute;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <CustomDatetime 
          id={"makeTimeField-" + name}
          labelText={label === false ? "" : name}
          compact={compact}
          dateFormat={false}
          timeFormat={format ? format : "hh:mm a"}
          readOnly={true}
          formControlProps={{
            fullWidth: true,
          }}
          value={date ? new Date(date) : null}
          onChange={() => {}}
          disabled
        />
      </GridItem>
    );
  }

  const makeNumberField = (name, value, key, attribute) => {
    const { columns, prefix, suffix, label=true, defaultvalue } = attribute;
    const fieldValue = value && value !== '' ? value : (defaultvalue ? defaultvalue : '');
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <CustomNumber 
          key={key}
          id={"makeNumberField-" + name}
          labelText={label === false ? "" : name}
          compact={compact}
          placeholder={""}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
            endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null,
            alignright: label === false,
          }}
          onChange={() => {}}
          value={fieldValue}
          />
      </GridItem>
    );
  }

  const makeQuantityField = (name, value, key, attribute) => {
    const { columns, label=true, defaultvalue='', suffix="" } = attribute;

    var fieldUnit = child.traits[name + " unit"];
    var fieldValue = defaultvalue;
    if(value) {
      if(value["value"] !== undefined) {
        fieldValue = value.value;
        fieldUnit = value.unit;
      } else {
        fieldValue = value && value !== '' ? value : defaultvalue;
      }
    }

    const fieldSuffix = fieldUnit ? fieldUnit : suffix;

    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <CustomNumber 
          key={key}
          id={"makeQuantityField-" + name}
          labelText={label === false ? "" : name}
          compact={compact}
          placeholder={""}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            endAdornment: <InputAdornment position="end">{fieldSuffix ? fieldSuffix : ""}</InputAdornment>,
          }}
          onChange={() => {}}
          value={fieldValue ? fieldValue : ""}
          />
      </GridItem>
    );
  }

  const makeChipField = (name, value, key, attribute) => {
    const { columns, label=true } = attribute;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <AutocompleteTags
          key={key}
          id={"makeChipField-" + name}
          labelText={label === false ? "" : name}
          placeholder=""
          disableClearable
          compact={compact}
          options={value ? value : []} 
          value={value ? value : []}
          renderTags={(options, getTagProps) => 
            options.map((option, innerKey) => { 
              if(option === null || option === '') {
                return null;
              }
              return (
                <Chip 
                  {...getTagProps(option, key)}
                  size='small'
                  label={option} 
                  onDelete={undefined}
                  key={innerKey}
                /> 
              );
            })
          }
          onChange={()=>{}}/>
      </GridItem>
    );
  }
 
  const makeListTextField = (name, value, key, attribute) => {
    const formatted = value ? value.join(", ") : '';
    return makeTextField(name, formatted, key, attribute);
  }

  const makeAttributeField = (name, value, key, attribute) => {
    if(value) {
      const { columns=6, } = attribute;

      return value.map((ca) => {
        return makeTextField(ca.name, ca.value, ca.name, {columns: columns});
      })
    }

    return null;
  }

  const makeTextField = (name, value, key, attribute) => {
    const { columns, prefix, suffix, label=true, defaultvalue } = attribute;
    const fieldValue = value && value !== '' ? value : (defaultvalue ? defaultvalue : '');
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <CustomInput
          key={key}
          labelText={label === false ? "" : name}
          id={"makeTextField-" + name}
          compact={compact}
          formControlProps={{
            fullWidth: true,
          }}
          inputProps={{
            startAdornment: prefix ? <InputAdornment position="start">{prefix}</InputAdornment> : null,
            endAdornment: suffix ? <InputAdornment position="end">{suffix}</InputAdornment> : null,
            value: fieldValue,
          }}
        />
      </GridItem>
    );
  }

  const makeCarouselField = (name, value, key, attribute) => {
    const { unitId, traits, unitIds } = unit;
    const buildingId = traits ? traits["Property Identifier"] : '';

    const { columns, height="Medium", slides=1 } = attribute;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}> 
        <ArtifactCarousel key={key}
          slides={+slides}
          height={height}
          artifactsorder={artifactsorder}
          filterIds={[unitId, buildingId, ...unitIds]}/>
      </GridItem>
    );
  }
  
  const makeActionButtons = (unit, columns) => {
    const { unitId, } = unit;
    const status = unitstatus[unitId] ? unitstatus[unitId].status : unit.status; 
    const comments = props.comments[unitId];
    return (
      [ { color: "info", icon: ClarificationIcon, tooltip: 'Seek clarification for proposed unit', state: 'Clarification', count: 0 },
        { color: "success", icon: AcceptIcon, tooltip: 'Accept proposed unit', state: 'Accepted', count: 0 },
        { color: "danger", icon: RejectIcon, tooltip: 'Reject proposed unit', state: 'Rejected', count: 0 },
        { separator: true },
        { color: "primary", icon: ActivityIcon, tooltip: 'View comments', state: '', count: comments ? comments.length : 0 },
      ].map((prop, key) => {
        const { color, tooltip, icon, count, state, separator} = prop;
        if(separator) {
          if(columns > 4) {
            return <Divider key={key} orientation="vertical" flexItem className={classes.divider} />;
          }
          return null;
        }
 
        return (
          <Tooltip key={key} title={tooltip} placement="top">
            <Button
              simple={status !== state}
              noDebounce
              color={color}
              className={icon ? classes.actionButton : classes.actionTextButton}
              onClick={() => onClickActions(unitId, 0, key, tooltip, comments)}
              key={key}>
                {icon ? 
                <Badge anchorOrigin={{vertical: 'top', horizontal: 'right'}} 
                        badgeContent={count} variant="dot" color="secondary">
                    <prop.icon className={classes.icon} />
                </Badge>
                : tooltip
                }
            </Button>
          </Tooltip>
        );
    }));  
  }

  const makeStatus = (name, value, key, attribute) => {
    const { columns, align="flex-end" } = attribute;
    const justify = align !== '' ? align.toLowerCase() : "flex-end";
    const status = unitstatus[unit.unitId] ? unitstatus[unit.unitId].status : unit.status;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <GridContainer justifyContent={justify} className={classes.actionButtonMargin} align={justify}>
          <InputLabel className={classes.labelRoot}>{name}: {status}</InputLabel>
        </GridContainer>
      </GridItem>
    );
  }
 
  const makeActions = (name, value, key, attribute) => {
    const preview = localStorage.getItem("preview");
    const { columns, align="flex-end" } = attribute;
    const justify = align !== '' ? align.toLowerCase() : "flex-end";
    if(preview === "true") {
      return (
        <GridItem key={key} xs={12} sm={12} md={columns}>
          <h4><small>Preview mode</small></h4>
        </GridItem>
      );
    }
  
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <GridContainer justifyContent={justify} className={classes.actionButtonMargin + " navigationTour-step4"}>
          {makeActionButtons(unit, columns)}
        </GridContainer>
      </GridItem>
    );
  }
 
  const isAdvancedMapsActivated = () => {
    const featuresList = localStorage.getItem("features");
    const features = featuresList ? JSON.parse(featuresList) : [];
    return features && features.includes("Maps");
  }

  const makeMapField = (name, value, key, attribute) => {
    const { columns=12, height="medium", markers="Current unit" } = attribute;
    const { XMarkers=[] } = unit.traits;
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <RegularMap
          zoom={mapdefaults.zoom}
          center={mapdefaults.center}
          showfilters={isAdvancedMapsActivated()}
          height={height.toLowerCase()}
          markers={markers === "Current unit" ? XMarkers : props.markers}
          onPageChange={onPageChange}
        />
      </GridItem>
    )
  }
  
  const makeFields = (children) => {
    // no children?
    if(!children) {
      return null;  // do nothing
    } 

    const { traits } = child;
    const fields = Object.keys(children).map((name, rowKey) => {
      const { type, key=name, fieldtype, fieldkey=name, format } = children[name];
      const value = traits ? traits[name] : '';

      switch(type) {
        case 'Carousel': 
          return makeCarouselField(name, value, rowKey, children[name]);

        case 'Map': 
          return makeMapField(name, value, rowKey, children[name]);

        case 'Group': 
          return makeGroup(name, value, rowKey, children[name]);

        case 'Status': 
          const status = traits ? traits['Proposal unit status'] : '';
          return makeStatus(name, status, rowKey, children[name]);

        case 'Actions': 
          return makeActions(name, value, rowKey, children[name]);

        case 'Section': 
          return makeSection(name, value, rowKey, children[name]);

        case 'TableMapping':
          const entryKey = fieldkey ? fieldkey : key;
          const tableentries = traits ? traits[entryKey] : {};
          return makeTable(name, tableentries, rowKey, children[name]);

        default:
          break;
      }

      switch(fieldtype) {
        case 'Date': 
          return makeDateField(name, format, value, key, children[name]);
        
        case 'Time': 
          return makeTimeField(name, format, value, key, children[name]);

        case 'Number': 
          return makeNumberField(name, value, key, children[name]);

        case 'Quantity': 
          return makeQuantityField(name, value, key, children[name]);

        case 'List': 
          return makeListTextField(name, value, key, children[name]);

        case 'Chip': 
          return makeChipField(name, value, key, children[name]);

        case 'Attribute': 
          return makeAttributeField(name, value, key, children[name]);

        default:
          return makeTextField(name, value, key, children[name]);
      }
    });

    return fields;
  }

  const makeGroup = (name, value, key, group) => {
    const { children, columns, align="flex-start"} = group;

    // not children??? stop the processing
    if(Object.keys(children).length === 0) {
      return null;
    }

    // make a group (grid item and inside a new grid container)
    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <GridContainer justifyContent={align !== '' ? align.toLowerCase() : "flex-start"}>
          {makeFields(children)}
        </GridContainer>
      </GridItem>      
    );
  } 
 
  const makeSectionFooter = () => {
    const { currentChildUnitIds } = props;
    if(currentChildUnitIds.length > 0) {
      return (
      <CardFooter product>
        <div className={classes.grow} />
        <PaginationView 
          root={false}
          page={childpage}
          pages={currentChildUnitIds.length + 1}
          size={"small"}
          onChange={(event, page) => onChildPageChange(page)}
        />
      </CardFooter>);
    }
    return <></>
  }

  const makeSpeedDialControl = (name) => {
    const { currentChildUnitIds } = props;
    if(currentChildUnitIds.length > 0) {
      return (
        <Fragment>
          <SpeedDial 
            ariaLabel="Show merged unit details"
            icon={<SpeedDialIcon/>}
            open={open[name] ? open[name] : false}
            onClose={()=>setOpen({...open, [name]: false})}
            onOpen={()=>setOpen({...open, [name]: true})}
            className={classes.speedDial}
            direction={"down"}
            FabProps={{size: "small", color: "inherit"}}
          >
            {[unit.unitId, ...currentChildUnitIds].map((unitId, key) => {
                return (
                  <SpeedDialAction
                    title={key === 0 ? "Combined unit" : `Unit ${key}`}
                    key={key}
                    icon={key + 1}
                    onClick={()=>onChildPageChange(key+1)}
                  />
                )
              })
            }
          </SpeedDial>
        </Fragment>
      );
    }
    return <></>
  }

  const makeTable = (name, value, key, table) => {
    const { columns, children } = table;
    const columnnames = Object.keys(children);

    var data = [];
    if(value) {
      var formatter = new Intl.NumberFormat();
      data = value.map((row) => {
        const rowdata = columnnames.map((columnname) => {
          var { key, fieldtype, prefix, suffix, format } = children[columnname];
          var field = row[key];

          if(field) {
            switch(fieldtype) {
              case 'Date':
                field = new moment(field).format(format ? format : "DD MMM YYYY");
                break;
  
              case 'List':
              case 'Chip':
                field = field.join(", ");
                break;

              case 'Number':
                field = isNaN(field) ? field : formatter.format(field);
                break;

              case 'Quantity':
                const quantity = field && field.value ? field.value : field;
                const unit = field && field.unit ? field.unit : row[key+"unit"];
                field = isNaN(quantity) ? quantity : `${formatter.format(quantity)} ${unit}`;
                break;

              default:
                field  = String(field);
                break;
            }
            // field has no value or is empty; so return empty string
            if(field === "") {
              return field;
            }
            // add configured prefix and suffix for the field
            const value = `${prefix ? prefix : ""} ${field ? field : ""} ${suffix ? suffix : ""}`; 
            return value.trim();
          }

          // special handling for actions
          if(fieldtype === 'Actions') {
           return makeActionButtons(row, 0)
          }

          return "";  // field has no value; so return an empty string
        });
        
        // if row is flagged, show the same in a different color
        if(row.flagged) {
          return { color: "success", data: rowdata }
        }

        return rowdata;
      });
    }

    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <Table
          id={"makeTable-" + name}
          key={key}
          hover
          verticalGridLines
          compare={false}
          compact={compact}
          compacthead={compact}
          tableHead={columnnames}
          tableData={data}
        />
      </GridItem>
    )
  }

  const makeSection = (name, value, key, section) => {
    const { children, columns, header=true, headertype="Standard", color="gray", labelalign="flex-end", childunits=false } = section;
    const headingColor = color !== '' ? color.toLowerCase() : 'gray';

    return (
      <GridItem key={key} xs={12} sm={12} md={columns}>
        <Card key={key}>
          <GridContainer justifyContent={labelalign !== '' ? labelalign.toLowerCase() : 'flex-start'}>
            {childunits === true && makeSpeedDialControl(name)}
            {header && 
              <Fragment>
                {headertype === "Simple" ? (
                  <h4 className={classes.cardNormalTitle}>
                    <b>{name}</b>
                  </h4>
                ) : (
                  <CardHeader plain compact={compact}>
                    <CardText className={classes.headerLabelMargin} compact={compact} color={headingColor}>
                      <b>{name}</b>
                    </CardText>
                  </CardHeader>
                )}
              </Fragment>
            }
          </GridContainer>
          <CardBody>
            <GridContainer>
              {makeFields(children)}
            </GridContainer>
          </CardBody>
          {childunits === true && makeSectionFooter()}
        </Card>
      </GridItem>      
    );
  }

  return (
    <div>
      <GridContainer>
        { rows > 0 ?
          (makeFields(structure)) : null
        }
        <GridItem xs={12} sm={12} md={12}>
          { rows === 0 ?
          (<GridContainer justifyContent="center">
            <h3 ><small>{messages[messageKey]}</small></h3>
          </GridContainer>) :  null
          }
        </GridItem>
      </GridContainer>

    </div>
  );
}
