import axios from "store/axios-broker";
import * as types from 'store/types';

const started = (request) => {
	return {
		type: types.REQUIREMENT_START,
		request: request
	};
};

const succeeded = (requirement) => {
	return {
		type: types.REQUIREMENT_SUCCESS,
    requirement: requirement,
	};
};

const failed = (error) => {
	return {
		type: types.REQUIREMENT_FAILURE,
		error: error && error.data && error.data.code ? error.data.code : "000"
	};
};

export const retrieve = (requirementId) => {
	return dispatch => {
		dispatch(started('retrieve'));
		let url = "/apex/consumer/" + requirementId + "/requirement";
		axios.get(url).then(response => {
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};
