import React, { Component } from 'react';
import { connect } from 'react-redux';

// core components
import GridContainer from "components/Grid/GridContainer";

// echelon compoennts
import RequirementDetailsView from './RequirementDetailsView';
import { messages } from "variables/messages";

import * as requirement from "store/actions/requirement";

class RequirementDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basic: {
        status: 'New',
        requirementId: null,
        clientId: null,
        contactId: null,
      },
      owners: [],
      type: '',
      specification: {},
      traits: {},

      requirementId: null,

      valid: {},
      modifiedDate: -1,
      contacts: [],
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { requirement } = props;
    if(requirement && requirement.modifiedDate > state.modifiedDate) {      
      return {
        basic: {
          status: requirement.status,
          requirementId: requirement.requirementId,
          clientId: requirement.clientId,
          contactId: requirement.contactId,
          userId: requirement.userId,          
        },
        owners: requirement.ownerIds,
        type: requirement.traits.type,
        specification: requirement.specification,
        traits: requirement.traits,
        
        requirementId: requirement.requirementId,

        valid: {},
        modifiedDate: requirement.modifiedDate,
        contacts: [],
      }
    }

    return null;
  }

  componentDidMount() {
    const { proposal } = this.props;
    const { requirement } = proposal ? proposal.traits : {};
    if(requirement) {
      this.props.retrieve(requirement.requirementId);
    }
  }

  render() {
    const { modifiedDate } = this.state;

    // requirements found? they show the requirement details
    if(modifiedDate > 0) {
      return (
        <RequirementDetailsView
          {...this.state}
        />
      );
    }

    // no requirements loaded or found... show message to the user
    return (
      <GridContainer justify="center">
        <h3 ><small>{messages["norequirements"]}</small></h3>
      </GridContainer>
    )
  }

}

const mapStateToProps = (state, ownProps) => {
  return {
    proposal: state.proposal.proposal,
    requirement: state.requirement.requirement,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  return {
    retrieve: (requirementId) => dispatch(requirement.retrieve(requirementId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequirementDetails);
