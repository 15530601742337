import {
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader,
  whiteColor,
  blackColor,
  grayColor
} from "assets/jss/material-dashboard-pro-react.js";

const cardTextStyle = {
  cardText: {
    float: "none",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "3px",
    backgroundColor: grayColor[0],
    padding: "15px",
    marginTop: "-20px"
  },
  cardProposalText: {
    justify: "center",
    display: "inline-block",
    marginRight: "0",
    borderRadius: "0px",
    backgroundColor: "transparent",
    padding: "0px",
    marginTop: "-16px",
    color: grayColor[0],
    borderBottom: "4px solid rgb(212, 212, 212)"
  },
  cardCompactText: {
    padding: "5px",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  grayCardHeader: {
  },
  cardHeaderText: {
  },
  cardHeaderIcon: {
  },
  orangeCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderText):not($cardHeaderIcon)": {
      ...warningCardHeader
    }
  },
  greenCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderText):not($cardHeaderIcon)": {
      ...successCardHeader
    }
  },
  redCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderText):not($cardHeaderIcon)": {
      ...dangerCardHeader
    }
  },
  blueCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderText):not($cardHeaderIcon)": {
      ...infoCardHeader
    }
  },
  blackCardHeader: {
    color: whiteColor,
    "&:not($cardHeaderText):not($cardHeaderIcon)": {
      background:
      "linear-gradient(60deg, " + blackColor + ", " + blackColor + ")",
    }
  },
  warningCardHeader,
  successCardHeader,
  dangerCardHeader,
  infoCardHeader,
  primaryCardHeader,
  roseCardHeader
};

export default cardTextStyle;
