import axios from "store/axios-broker";
import * as types from "store/types.js";

const started = (request) => {
  return {
      type: types.AUTHORIZE_START,
      request: request,
      error: null
  };
};

const succeeded = (proposal) => {
  return {
      type: types.PROPOSAL_SUCCESS,
      proposal: proposal,
  };
};

const failed = (error) => {
  return {
      type: types.PROPOSAL_FAILURE,
      error: error && error.data && error.data.code ? error.data.code : "000"
  };
};

export const retrieve = (proposalId) => {
	return dispatch => {
		dispatch(started('retrieve'));
		let url = "/apex/consumer/" + proposalId + "/proposal";
		axios.get(url).then(response => {
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};

export const forward = (proposalId, traits) => {
	return dispatch => {
		dispatch(started('update'));
		let url = "/apex/consumer/" + proposalId + "/proposal/forward";
		axios.post(url, traits).then(response => {
			dispatch(succeeded(response.data));
		}).catch(error => {
      dispatch(failed(error.response));
		});
	};
};
