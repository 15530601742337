import React, { useState } from "react";
import { messages } from "variables/messages";

import Snackbar from "components/Snackbar/Snackbar.js";

export default function NoticeView(props) {
  const { code, color, icon, place, open, closeNotification } = props;
  
  React.useEffect(() => {
    // Specify how to clean up after this effect:
    return function cleanup() {
      if(timeoutId > 0) {
        onCloseNotification();
        window.clearTimeout(timeoutId);
      }
    };
  });

  const [timeoutId, setTimeoutId] = useState(0);

   // error messages that requires to be shown to the user
   const errors = {
    ...messages,
  };
 
  const setAutoClose = () => {
    if(timeoutId === 0) {
      const id = setTimeout(() => {
        onCloseNotification();
      }, 6000);
      setTimeoutId(id);
    }
  }

  const onCloseNotification = () => {
    if(closeNotification) {
      closeNotification();
    }
    setTimeoutId(0);
  }

  return (
    <div>
      {open ? setAutoClose() : null}
      <Snackbar
        place={place}
        color={color}
        icon={icon}
        message={errors[code]}
        open={open}
        close
        closeNotification={onCloseNotification}
      />  
    </div>
  );
}