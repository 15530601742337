import React, { Component } from 'react';
import { connect } from 'react-redux';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Quote from "components/Typography/Quote";

// echelon components
import NavigationTour from "views/Client/Tours/NavigationTour";
import PortraitView from './PortraitView';

// common components
import { updatestate } from 'variables/utilities';
import PaginationView from 'views/Components/Pagination/PaginationView';

class Portrait extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,

      markers: [],
      units: [],
      children: [],
      currentChildUnitIds: [],
      parent: {},
      child: {},
      unitsLoadedDate: -1,

      // view state
      start: 1,
      childpage: 1,

      // history
      comments: [],
      historiesModifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { units, unitsLoadedDate, filter } = props;
  
    if(units && unitsLoadedDate > state.unitsLoadedDate) {
      const filters = filter.filters ? filter.filters : [];
      const nonremovedunits = units.filter((unit) => unit.status !== 'Removed' && unit.type !== 'MergedChild');
      const nonremovedchildunits = units.filter((unit) => unit.status !== 'Removed' && unit.type === 'MergedChild');
      var markers = [];
      nonremovedunits.map((unit) => markers.push(...unit.traits.XMarkers));

      const index = Math.min(state.start-1, nonremovedunits.length);

      const { unitIds=[] } = nonremovedunits.length > 0 ? nonremovedunits[index] : {};
      const childUnitIds = unitIds.filter((unitId) => {
        var exists = false;
        nonremovedchildunits.map((child) => {
          if(child.unitId === unitId) {
            exists = true;
          }
          return null;
        });
        return exists;
      });
      
      const currentUnit = nonremovedunits.length > 0 ? nonremovedunits[index] : {};

      return {
        units: nonremovedunits,
        parent: currentUnit,
        child: currentUnit,
        children: nonremovedchildunits,
        currentChildUnitIds: childUnitIds,
        unitsLoadedDate: unitsLoadedDate,
        filters: filters,
        markers: markers,
        loading: false,
      }
    }

    return null;
  }

  onChange = (event, attribute, type, valid=true) => {
    const updated = updatestate(this.state, event, attribute, type, valid);
    this.setState(updated);
  }

  computeSize = () => {
    var size = 4;
    if(window.innerWidth < 420) {
      size = 1;
    } else if (window.innerWidth < 840) {
      size = 2;
    } else if (window.innerWidth <= 1024) {
      size = 3;
    }
    return size;
  }

  messageKey = () => {    
    const { access, code, error } = this.props;
    const initialKey = this.state.loading ? "retrieving" : "nounits";
    var messageKey = (error || code === null) ? "006" : initialKey;
    return (code !== undefined && code !== null && access === false) ? "noaccess" : messageKey 
  }

  onPageChange = (page) => {
    const { units, children } = this.state;
    const unit = units[page-1];
    const childUnitIds = unit.unitIds.filter((unitId) => {
      var exists = false;
      children.map((child) => {
        if(child.unitId === unitId) {
          exists = true;
        }
        return null;
      });
      return exists;
    });

    this.setState({child: unit, childpage: 1, parent: unit, 
                    currentChildUnitIds:childUnitIds, start: page, });
  }

  onChildPageChange = (page) => {
    const { parent, children, currentChildUnitIds } = this.state;

    // process the child unitId
    var child = parent;
    if(page > 1) {
      const childUnitId = currentChildUnitIds[page-2];
      const filtered = children.filter((child) => child.unitId === childUnitId);
      child = filtered[0];
    }

    this.setState({child: child, childpage: page});
  }

  render() {
    // now return the UI that needs to be rendered
    const { start, units, markers, parent, child, childpage, currentChildUnitIds,  } = this.state;
    const { unitstatus={}, disclaimer, structure, mapdefaults, compactView, comments } = this.props;
    const { columns=12, formcolor="none", outline="None" } = structure;
    const { compact=compactView } = this.props.filter;

    // form background and outline color
    const formBackgroundColor = formcolor ? formcolor.toLowerCase() : 'none';
    const formOutlineColor = outline ? outline : 'None';

    return (
      <Card plain nomargin>
        <NavigationTour 
          targets={{step1: '.navigationTour-step1',
                    step2: '.navigationTour-step2',
                    step3: '.navigationTour-step3',
                    step4: '.navigationTour-step4',
                  }}
        />
        <CardBody>
          <GridContainer justifyContent="center">
            {units.length > 0 ?
            (<GridItem xs={12} sm={12} md={columns}>
              <GridContainer centered alignItems="center">
                <PaginationView 
                  page={start} 
                  pages={units.length}
                  className={"navigationTour-step3"}
                  onChange={(event, page) => {this.onPageChange(page)}} />
              </GridContainer>       
            </GridItem> ) : null
            }
            <GridItem xs={12} sm={12} md={columns}>
              <Card color={formBackgroundColor} outlineColor={formOutlineColor}>
                <CardBody>
                  <PortraitView
                    key={start}
                    compact={compact}
                    unit={parent}
                    child={child ? child : parent}
                    currentChildUnitIds={currentChildUnitIds}
                    markers={markers}
                    comments={comments}
                    childpage={childpage}
                    artifactsorder={this.props.artifactsorder}
                    rows={units.length}
                    mapdefaults={mapdefaults}
                    structure={structure.children}
                    unitstatus={unitstatus}
                    messageKey={this.messageKey()}
                    onChange={this.onChange}
                    onSetFilters={this.props.onSetFilters}
                    onClickActions={this.props.onClickActions}
                    onPageChange={this.onPageChange}
                    onChildPageChange={this.onChildPageChange}
                    openDocumentDrawer={this.props.openDocumentDrawer}
                  />
                </CardBody>
              </Card>
            </GridItem>
            {disclaimer && disclaimer.length > 0 && units.length > 0 ?
            (<GridItem xs={12} sm={10} md={columns - 1}>
              <Quote small text={disclaimer} author={localStorage.getItem("businessName")}/>
            </GridItem>) : null
            }
          </GridContainer>
        </CardBody>
      </Card>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    error: state.proposal.error,
    histories: state.proposalhistory.histories,
    historiesModifiedDate: state.proposalhistory.histories,
    units: state.proposalunit.units,
    unitsLoadedDate: state.proposalunit.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Portrait);