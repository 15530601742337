import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

// core components
import GridContainer from 'components/Grid/GridContainer';
import GridItem from 'components/Grid/GridItem';
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";

import fallbackImage from "assets/img/building.jpeg";

// echelon components
import PaginationView from 'views/Components/Pagination/PaginationView';
import BuildingCardView from "views/Client/Building/BuildingCardView";
import CompareView from './CompareView';

// common components
import { updatestate } from 'variables/utilities';

class Compare extends Component {
  constructor(props) {
    super();
    this.state = {
      loading: true,

      unitList: [],
      unitsHeader: [],
      filteredList: [],
      filteredHeader: [],

      units: [],
      unitsLoadedDate: -1,

      // view state
      start: 0,

      // history
      historiesModifiedDate: -1,
    }
  }

  static getDerivedStateFromProps(props, state) {
    const { histories, historiesModifiedDate, units, unitsLoadedDate, filter } = props;
  
    if(units && (unitsLoadedDate > state.unitsLoadedDate ||
                  historiesModifiedDate || state.historiesModifiedDate)) {
      var formatter = new Intl.NumberFormat();
      const { flattended } = props;
      const unitList = units.filter((unit) => unit.status !== 'Removed' && unit.type !== 'MergedChild'
      ).map((unit) => {
        const { traits } = unit;
        const attributes = flattended.filter((attribute) => {
          const { type, compareheader=true } = attribute;
          return type !== 'Section' || (type === 'Section' && compareheader === true);
        }).map((attribute) => {
          const { name, type, fieldtype, format, defaultvalue='' } = attribute;
          if(type === 'Section') {
            return { type: type };
          }

          if (type === 'Status') {
            return traits['Proposal unit status'];
          }

          if (type === 'TableMapping') {
            const { key=name, fieldkey=name, fields=[] } = attribute;
            const tableentries = traits ? traits[fieldkey] : {};
            const rows = tableentries ? tableentries : traits[key];
            if(rows) {
              const fieldname = fields.length > 0 ? fields[0] : "name";
              return rows.map((row) => row[fieldname]).join(", ");
            }
            
            return null;
          }

          const value = traits[name];
          switch(fieldtype) {
            case 'Date':
              return value ? new moment(value).format(format ? format : "DD MMM YYYY") :  defaultvalue;

            case 'Time':
              return value ? new moment(value).format(format ? format : "hh:mm a") :  defaultvalue;
  
            case 'List':
            case 'Chip':
              return value ? value.join(", ") : defaultvalue;

            case 'Number':
              var fieldValue = defaultvalue; 
              if(value) {
                fieldValue = isNaN(value) ? value : formatter.format(value);
              }
              return fieldValue ? fieldValue : defaultvalue;

            case 'Quantity':
              var formattedValue = defaultvalue;
              if(value && (value.value || value.value === '' || value.unit)) {
                formattedValue = isNaN(value.value) ? 
                                  `${formatter.format(value.value)} ${value.unit}` : 
                                  `${value.value} ${value.unit}`;
              } else {
                formattedValue = value ? value : defaultvalue;
              }
              return formattedValue ? formattedValue : defaultvalue;
              
            default:
              return value ? String(value) : defaultvalue;
          }
        });
        
        return [traits["Unit number"], traits["Furnishing type"], traits["Classification"], 
                unit.status, ...attributes];
      });
      
      const unitHeader = units.filter((unit) => unit.status !== 'Removed' && unit.type !== 'MergedChild')
      .map((unit) => {
        const { traits } = unit;
        const unitNumber = traits["Unit number"] && traits["Floor"] ?  
                            traits["Unit number"] + " | " + traits["Floor"] : undefined;

        const comments = histories.filter((history) => history.unitId === unit.unitId)
                              .sort((a, b) => b.modifiedDate - a.modifiedDate )
                              .map((history) => history.comment);
        return (
          [ traits["Unit number"], traits["Furnishing type"], traits["Classification"], unit.status, 
            traits["Building name"], 
            unitNumber, 
            traits["Unit area"],
            unit.unitId,
            traits["Property Identifier"],
            comments,
          ]
        );
      });

      const filters = filter.filters ? filter.filters : [];
      return {
        units: units.filter((unit) => unit.status !== 'Removed'),
        unitList: unitList,
        unitsHeader: unitHeader,
        filteredList: unitList.filter((unit) => filters.includes(unit[0])),
        unitsLoadedDate: unitsLoadedDate,
        historiesModifiedDate: historiesModifiedDate,
        loading: false,
      }
    }

    return null;
  }

  onChange = (event, attribute, type, valid=true) => {
    const updated = updatestate(this.state, event, attribute, type, valid);
    this.setState(updated);
  }

  computeSize = () => {
    var size = 4;
    if(window.innerWidth < 420) {
      size = 1;
    } else if (window.innerWidth < 840) {
      size = 2;
    } else if (window.innerWidth <= 1024) {
      size = 3;
    }
    return size;
  }

  messageKey = () => {    
    const { access, code } = this.props;
    const initialKey = this.state.loading ? "retrieving" : "nounits";
    var messageKey = code === null ? "006" : initialKey;
    return (code !== undefined && code !== null && access === false) ? "noaccess" : messageKey 
  }

  render() {
    const { flattended, compactView, disclaimer, proposal } = this.props;
    const { unitsHeader, unitList, start, units } = this.state;
    const { compact=compactView, unitNumbers=[],
            filters=[], furnishing=[], classification=[] } = this.props.filter;

    // filter, slice and make the cards for the relevant units
    const size = this.computeSize();
    const slicedUnitsHeader = unitsHeader.filter((header) => {
      return ((filters.length > 0 && filters.includes(header[3]))
                || (filters.length === 0 && header[3] !== 'Removed')) 
              && (furnishing.length === 0 || furnishing.includes(header[1]))
              && (unitNumbers.length === 0 || unitNumbers.includes(header[0]))
              && (classification.length === 0 || classification.includes(header[2]))
    }).slice(start, (start+size));
    const { covers } = proposal && proposal.traits ? proposal.traits : { covers: {} }; 
    const header = slicedUnitsHeader.map((header, index) => {
      var image = fallbackImage;
      if(covers) {
        var artifactId = Object.keys(covers).filter((key) => key === header[7])[0];
        if(!artifactId) {
          artifactId = Object.keys(covers).filter((key) => key === header[8])[0];
        }
        if(artifactId) {
          image = "/apex/porter/" + covers[artifactId] + "/document";
        }
      }
      const status = header[3].toLowerCase();
      return (
        <GridContainer justifyContent="center">
          <GridItem>
            <BuildingCardView 
              compact={compact}
              name={header[4]}
              location={header[5]}
              image={image}
              number={start+index+1}
              size={header[6]}
              status={{[status]: true}}
              onClick={()=>this.props.openDocumentDrawer([header[7], header[8]])}
            />
          </GridItem>
        </GridContainer>
      )
    });

    // filter based on needed filters and slice the needed rows
    const filtered = unitList.filter((unit) => {
      return ((filters.length > 0 && filters.includes(unit[3]))
              || (filters.length === 0 && unit[3] !== 'Removed'))
              && (furnishing.length === 0 || furnishing.includes(unit[1]))
              && (unitNumbers.length === 0 || unitNumbers.includes(unit[0]))
              && (classification.length === 0 || classification.includes(unit[2]))
    });
    const sliced = filtered.slice(start, (start+size));

    const attributenames = flattended.filter((attribute) => {
      const { type, compareheader=true } = attribute;
      return type !== 'Section' || (type === 'Section' && compareheader === true);
    }).map((attribute) => {
      const { name, type, prefix, suffix, label=true } = attribute;
      if(type === 'Section') {
        return {name: name, ...attribute};
      }
      const prefixvalue = prefix ? `${prefix} ` : '';
      const suffixvalue = suffix ? ` (${suffix})` : '';
      return (prefixvalue + (label === false ? '' : name) + suffixvalue).trim();
    });

    // add the attributes and transpose the table
    const names = ['Unit number', 'Furnishing type', 'Classification', 'Status', ...attributenames];
    const attributes = [names, ...sliced];
    const data = Object.keys(attributes[0]).map(function(c) {
      return attributes.map(function(r) { return r[c]; });
    });

    // now return the UI that needs to be rendered
    return (
      <div>
      <Card plain nomargin>
        <CardBody>
          <GridContainer justifyContent="center">
            {filtered.length > 0 ?
            (<GridItem xs={12} sm={12} md={12}>
              <GridContainer centered alignItems="center">
                <PaginationView siblingCount={size > 1 ? 2 : 1} page={start+1} pages={filtered.length} onChange={(event, page) => {this.setState({start: page-1})}} />
              </GridContainer>       
            </GridItem> ) : null
            }

            <GridItem xs={12} sm={12} md={12}>
            </GridItem>
            <CardBody>
              <Card plain>
                <CompareView
                  size={size}
                  messageKey={this.messageKey()}
                  disclaimer={disclaimer}
                  compact={compact || size <= 2}
                  next={((start+size) < filtered.length)}
                  previous={start > 0}
                  header={header}
                  units={units}
                  unitIds={slicedUnitsHeader}
                  rows={filtered.length}
                  unitsTable={data.slice(compact ? 4 : 4)}
                  onChange={this.onChange}
                  onSetFilters={this.props.onSetFilters}
                  onClickActions={this.props.onClickActions}
                  openDocumentDrawer={this.props.openDocumentDrawer}
                />
              </Card>
            </CardBody>
            </GridContainer>
          </CardBody>
        </Card>

      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    units: state.proposalunit.units,
    histories: state.proposalhistory.histories,
    historiesModifiedDate: state.proposalhistory.histories,
    unitsLoadedDate: state.proposalunit.modifiedDate,
  };
};

const mapDispatchToProps = dispatch => {
  return {
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Compare);
