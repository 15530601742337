import * as types from "store/types.js";
import { update } from 'variables/utilities';

const initial = {
  proposal: null,
  units: null,
};

const started = (state, action) => {
  return update( state, {
          request: action.request,
          error: null
  });
};

const succeeded = (state, action) => {
  return update( state, {
          proposal: action.proposal,
          modifiedDate: new Date(),
   });
};

const failed = (state, action) => {
  return update( state, {
          error: action.error
  });
}

const proposal = (state = initial, action) => {
  switch ( action.type ) {
    case types.PROPOSAL_START: 
        return started(state, action);

    case types.PROPOSAL_SUCCESS: 
        return succeeded(state, action);

    case types.PROPOSAL_FAILURE: 
        return failed(state, action);

    default:
        return state;
  }
};

export default proposal;